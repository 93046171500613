import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Styles/Collections.css';
import { API_BASE } from '../constant';

class Collection extends Component {
  render() {
    const { collectionsData } = this.props;
    return (
      <div>
        <div className="collections-wrapper">

          <div className="headout-picks-nav">
            <div>
              <h1>Referenten im Fokus</h1>
            </div>
            <div className="view-all">
                            <Link
                              to={{ pathname: `/referenten` }}
                              key={'v_sra'}
                              className="link_sa view-all"
                            >
                              <h3>Alle anzeigen</h3>
                              <i className="fas fa-arrow-right" />
                            </Link>
                      </div>
          </div>
          <hr
            style={{
              backgroundColor: '#355fb3',
              width: '75px',
              height: '2px',
              border: 'none',
              marginTop: '0px',
              marginLeft: '0px'
            }}
          />
        </div>
        <div className="cards-wrapper">
          {collectionsData &&
            collectionsData.filter(v => v.Published === true).map(({ image, Bild, Name, Vorname, id, name, description }) => (
            <Link
                              to={{ pathname: `/referenten/${id}` }}
                              key={'v_sa' + id}
                              className="link_sa view-all"
                            >
                            <CollectionCard key={id} url={(((Bild || {}).data || {}).attributes)  ? (API_BASE + Bild.data.attributes.url) : "/pmp.png"} description={Name + ", " + Vorname} /></Link>
            ))}
        </div>
      </div>
    );
  }
}

export class CollectionCard extends Component {
  render() {
    const url = `url(${this.props.url})`;
    return (
      <div className="collection-card-wrapper">
        <div className="card-parent">
          <div
            className="card-child"
            style={{
              backgroundImage: url
            }}
          >
            <p>{this.props.description}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Collection;
