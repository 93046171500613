import React, { useEffect, useState, useContext } from "react";
import { Modal, Button, message, Card, Col, Form, Input, Select, Row, Spin, Switch, Divider, Upload } from "antd";
import { PlusOutlined, MinusCircleOutlined , FrownOutlined, SafetyCertificateOutlined, MehOutlined, SmileOutlined, InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import type { GetProp, UploadFile, UploadProps } from 'antd';
import { PicturesWall } from './PicturesWall';
import PdfViewer from './PdfViewer';
import { AuthContext } from "../context/AuthContext";
import localObject from '../germanLocale.json';
import { API, API_BASE, STRAPI_API } from "../constant";
import { getToken } from "../helpers";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { useAuthContext } from "../context/AuthContext";

const { Option } = Select;

const categoryMappings = {
    "Ernährung": {
        "Trink- und Aufbaunahrung": {
        "Appetitlosigkeit": {},
        "Blutzuckerschwankungen": {},
        "Darmregulationsstörung (Durchfall/Verstopfung)": {},
        "Gestörte Nährstoffverwertung": {},
    },
    "Sondennahrung": {
      "Blutzuckerschwankungen": {},
      "Darmregulationsstörung (Durchfall/Verstopfung)": {},
      "Fettverwertungsstörung": {},
      "Gestörte Nährstoffverwertung": {},
    },
    "Applikationstechnik": {
        "Bolusapplikation": {},
        "Schwerkraftapplikation": {},
        "Pumpenapplikation": {},
    }
},
    "Wunde": {
        Wundauflage: {
            Hydrokolloid: {"Dünn": [],"Normal": []},
            Alginat: {"Kompressen": [],"Tamponade": []},
            Hydrofaser: {"Kompressen": [],"Tamponade": []},
            Hydrogel: {"Unkonserviert": [],"Konserviert": []},
            Schaumverband: {"Dünn": [],"Normal": []},
            "Semipermeable Wundfolie": {"Haftend": [],"Nicht-haftend": [],"Mit Silikon": [],"Ohne Silikon": [],"Feinporig": [],"Grobporig": []},
            "Aktivkohle": [],
            "Cavity": [],
            "Saugkompresse / Superabsorber": [],
            "Distanzgitter": {"Fettgaze": [],"Silikon": [],"Gelbildend": []},
            "Kollagen": [],
            "Hydrophobe Auflage": [],
            "Hydroaktive Auflage": [],
//            "Sonstiges": [],
        },
        "Wundspüllösung": {
            "Konservierte Wundspüllösung": [],
            "Antiseptikum": []
            },
        "Hautschutz- und Pflege": {
            "Medizinischer Hautschutz": {"Spray Applikator / „Lolly“ Creme": []},
            "Hautpflege": []
         },
        "Kompressionstherapie": {
            "Schlauchverband": [],
            "Polsterbinde": [],
            "Kurzzugbinden": {"Nicht-haftend": [],"Haftend": []},
            "Mehrkomponentensystem": [],
            "Kompressionsbandage": []
         },
    },
}




const convertFromUToStrapi = (data) => {
    const toReturn = {
    };
}

const normFile = (e) => {
  console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return (e || {}).fileList;
};

const HerstellerProfile = ({ type, id, veranstaltungenData }) => {
const { setUser, user, availableSubs, sub } = useAuthContext();
  const selectedSubTitle = (sub ? ((availableSubs || []).find((availableSub) => (availableSub || {}).stripePlanId === ((sub || {}).plan || {}).id) || {}).title : false || "");


const veranstaltung = veranstaltungenData.find((veranstaltung) => veranstaltung.id == id) || {};

    const [loading, setLoading] = useState(false);
    const [fileListProduct, setFileListProduct] = useState(((veranstaltung || {}).Produkt || []).map( el => (el.Medien || {}).data || []) );
    const [fileList, setFileList] = useState(((veranstaltung || {}).Medien || {}).data || []);
  const [hauptkategorie, setHauptkategorie] = useState({});
  const [categorie0, setCategorie0] = useState({});
  const [categorie1, setCategorie1] = useState({});
  const [categorie2, setCategorie2] = useState({});
  const [defaultValues, setDefaultValues] = useState({});
  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log('Received values:', values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  console.debug("hauptkategorie categorie0 categorie1 categorie2", hauptkategorie, categorie0, categorie1, categorie2);

    useEffect(() => {
        const currentValues = form.getFieldsValue();
        // Check if form values are empty
        const isFormEmpty = Object.values(currentValues).every(value => value === undefined || value === null || value === "");

        if (isFormEmpty) {
            form.setFieldsValue(defaultValues)
        }
    }, [form, defaultValues])

    useEffect(() => {
        const veranstaltung = veranstaltungenData.find((veranstaltung) => veranstaltung.id == id) || {};

        const defaultValues = {};
        Object.keys(veranstaltung).forEach((key) => {
            defaultValues[key] = veranstaltung[key];
        })

        setHauptkategorie(Object.keys(veranstaltung.Produkt || {}).reduce((acc, cur, index) => {
            acc[index] = veranstaltung.Produkt[cur].Hauptkategorie;
            return acc;
        }, {}));

        setCategorie0(Object.keys(veranstaltung.Produkt || {}).reduce((acc, cur, index) => {
            acc[index] = veranstaltung.Produkt[cur].Unterkategorie;
            return acc;
        }, {}));

        setCategorie1(Object.keys(veranstaltung.Produkt || {}).reduce((acc, cur, index) => {
            acc[index] = veranstaltung.Produkt[cur].Unterpunkt1;
            return acc;
        }, {}));

        setCategorie2(Object.keys(veranstaltung.Produkt || {}).reduce((acc, cur, index) => {
            acc[index] = veranstaltung.Produkt[cur].Unterpunkt2;
            return acc;
        }, {}));



        setDefaultValues({
            ...defaultValues, // veranstaltung.date || 0// new Date(veranstaltung.date).toISOString().split('T')[0],
         });

    }, [veranstaltungenData]);

  const handleVeranstaltungUpdate = async (data) => {
    setLoading(true);
    try {
      const veranstaltung = veranstaltungenData.find((veranstaltung) => veranstaltung.id == id);

      let response;

        const dataToPost = Object.fromEntries(
                               Object.entries(data).filter(([key, value]) => value !== undefined)
                             );

         if (fileList.length > 0) dataToPost["Medien"] = { data: fileList };

         if (!dataToPost["Logo"] || (veranstaltung && (dataToPost["Logo"] || {}).data)) delete dataToPost["Logo"];
//         if (!dataToPost["Medien"] || (veranstaltung && (dataToPost["Medien"] || {}).data)) delete dataToPost["Medien"];

         if ((((dataToPost.dragger || {})[0] || {}).response || {})[0]) dataToPost["Logo"]  = dataToPost.dragger[0].response[0];

         dataToPost.Medien = (fileList || []).map( media => {
            if (((media.response) || []) [0]) {
                return media.response[0];
            } else {
                return media;
            }
         });


         (dataToPost["Produkt"] || []).forEach((produkt, index) => {
              if (!produkt["Produktbild"] || (veranstaltung && (dataToPost.Produkt[index]["Produktbild"] || {}).data)) delete produkt["Produktbild"];
              if (!produkt["Datenblaetter"] || (veranstaltung && (dataToPost.Produkt[index]["Datenblaetter"] || {}).data)) delete produkt["Datenblaetter"];

              if ((((produkt || {}).draggerpdf || {})[0] || {}).response) dataToPost["Produkt"][index]["Datenblaetter"] = produkt.draggerpdf[0].response[0];
              if ((((produkt || {}).dragger || {})[0] || {}).response) dataToPost["Produkt"][index]["Produktbild"] = produkt.dragger[0].response[0];
              delete dataToPost["Produkt"][index].draggerpdf;
              delete dataToPost["Produkt"][index].dragger;

                       dataToPost["Produkt"][index].Medien = (fileListProduct[index] || []).map( media => {
                          if (((media.response) || []) [0]) {
                              return media.response[0];
                          } else {
                              return media;
                          }
                       });
         });

         dataToPost.createdby = (user || {}).id;

         delete dataToPost.dragger;

      if (veranstaltung) {
        response = await fetch(`${API}/herstellers/${veranstaltung.id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify({data: dataToPost}),
          });
      } else {
        response = await fetch(`${API}/herstellers`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify({data: dataToPost}),
          });
      }
      const responseData = await response.json();

      if (responseData.error) {
        alert("Es konnte nicht gespeichert werden, bitte schicken Sie unserem Support die genauen Daten die gespeichert werden sollten um das Problem zu lösen oder verändern Sie die Eingabe.");
      } else {
        // Update logic based on your application's requirements
        message.success("Daten erfolgreich gespeichert!");
        window.location.replace("/admin/meine-inserate");
      }
    } catch (error) {
      console.error(error);
      message.error("Es ist etwas schief gelaufen...");
    } finally {
      setLoading(false);
    }
  };


  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Form
          form={form}
          name="basic"
          initialValues={defaultValues}
          onFinish={handleVeranstaltungUpdate}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="Herstellername"
                name="Herstellername"
              >
                <Input placeholder="Herstellername" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Adresse"
                name="Addresse"
              >
                <Input placeholder="Adresse" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Beschreibung" name="Beschreibung">
              <ReactQuill placeholder="Beschreibung..." theme="snow" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Homepage"
                name="Homepage"
              >
                <Input placeholder="Homepage" />
              </Form.Item>
            </Col>
            {(((veranstaltung.Logo || {}).data || {}).attributes) && <Col span={12}><img className={"leafImage leafImage--veranstaltung"} src={API_BASE + veranstaltung.Logo.data.attributes.url}  /> </Col> }
                                <Col span={(((veranstaltung.Logo || {}).data || {}).attributes) ? 8 : 24}>
                                   <Form.Item label="Logo hochladen" name="Logo">
                                        <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                                          <Upload.Dragger name="files"
                                          crossOrigin={"use-credentials"}
                                          data={{
                                            ref: "api::veranstaltung.veranstaltung",
                                            field: "Logo",
                                          }}
                                          action={`${API}/upload`}
                                           headers={{ "Authorization": `Bearer ${getToken()}`}}>
                                            <p className="ant-upload-drag-icon">
                                              <InboxOutlined />
                                            </p>
                                            <p className="ant-upload-text">Klicken Sie hier oder ziehen Sie das Logo in diesen Bereich</p>
                                            <p className="ant-upload-hint">Bilder im Format .jpeg, png. oder .jpg werden unterstützt</p>
                                          </Upload.Dragger>
                                        </Form.Item>
                                      </Form.Item>
                                  </Col>

            { selectedSubTitle === "Hersteller: Premium" && <Col span={24}>
              <Form.Item label="Medien" name="Medien (Erlaubt sind sowohl PDF, als auch Bild-, Video- und Audioformate)">
                <PicturesWall fileList={fileList} setFileList={setFileList} />
              </Form.Item>
            </Col>}
            <Col span={24}>
            <div className="site-card-wrapper">
            <h2 style={{ textAlign: 'left', marginBottom: '8px' }}>Produkte</h2>
            <Form.List>
              {(fields) => (
                <div>
                  {fields.map((field) => (
                    <Form.Item {...field}>
                      <Input />
                    </Form.Item>
                  ))}
                </div>
              )}
            </Form.List>


              <Form.List name="Produkt">
              {(fields, { add, remove }) => (
                <div className="flexHack">
                      {fields.map((field, index) => (
                      <Row key={field.key} gutter={[16, 16]}>
            <Col span={24}> <h3 style={{ color: '#fff', textAlign: 'left', marginBottom: '8px' }}>Produkt #{index+1}</h3></Col>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            name={[field.name, 'Produktname']}
                            fieldKey={[field.fieldKey, 'Produktname']}
                            label="Produktname"
                          >
                            <Input placeholder="Produktname"/>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            name={[field.name, 'PZN']}
                            fieldKey={[field.fieldKey, 'PZN']}
                            label="PZN (falls vorhanden)"
                          >
                            <Input placeholder="PZN" />
                          </Form.Item>
                        </Col>
                        {((((((veranstaltung || {}).Produkt || {})[index] || {}).Produktbild || {}).data || {}).attributes) && <Col span={12}><img className={"leafImage leafImage--veranstaltung"} src={API_BASE + veranstaltung.Produkt[index].Produktbild.data.attributes.url}  /> </Col> }
                                                        <Col span={((((((veranstaltung || {}).Produkt || {})[index] || {}).Produktbild || {}).data || {}).attributes) ? 8 : 12}>
                                                           <Form.Item label="Produktbild hochladen" name={[field.name,"Produktbild"]}>
                                                                <Form.Item name={[field.name, "dragger"]} valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                                                                  <Upload.Dragger name="files"
                                                                  crossOrigin={"use-credentials"}
                                                                  data={{
                                                                    ref: "api::veranstaltung.veranstaltung",
                                                                    field: "Produktbild",
                                                                  }}
                                                                  action={`${API}/upload`}
                                                                   headers={{ "Authorization": `Bearer ${getToken()}`}}>
                                                                    <p className="ant-upload-drag-icon">
                                                                      <InboxOutlined />
                                                                    </p>
                                                                    <p className="ant-upload-text">Klicken Sie hier oder ziehen Sie das Logo in diesen Bereich</p>
                                                                    <p className="ant-upload-hint">Bilder im Format .jpeg, png. oder .jpg werden unterstützt</p>
                                                                  </Upload.Dragger>
                                                                </Form.Item>
                                                              </Form.Item>
                                                          </Col>

                        { selectedSubTitle === "Hersteller: Premium" && <Col span={12}>
                        <Form.Item
                            {...field}
                             label="Medien" name={[field.name, 'Medien']}>
                                        <PicturesWall
                                          fileList={fileListProduct[index] || []}
                                          setFileList={(p) => {
                                            const updatedFileList = [...fileListProduct];
                                            updatedFileList[index] = p;
                                            setFileListProduct(updatedFileList);
                                          }}
                                        />
                                      </Form.Item>
                        </Col>}
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            label="Hauptkategorie"
                            name={[field.name, 'Hauptkategorie']}
                          >
                            <Select placeholder="Hauptkategorie" onSelect={ (v) =>  { console.debug("hauptkategorie",v); setHauptkategorie({ ...hauptkategorie, [index]: v }) }}>
                              <Option value="Ernährung">Ernährung</Option>
                              <Option value="Wunde">Wunde</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            {...field}

                            label="Unterkategorie"
                            name={[field.name, 'Unterkategorie']}
                          >
                            <Select onSelect={ (v) => { setCategorie0({ ...categorie0, [index]: v }); } } placeholder="Unterkategorie"  disabled={ !(hauptkategorie[index]) }>
                              { hauptkategorie[index] ? Object.keys(categoryMappings[hauptkategorie[index]]).map((cat) => {
                                return <Option value={cat}>{cat}</Option>
                              }) : null }
                            </Select>
                          </Form.Item>
                        </Col>
                        { hauptkategorie[index] === "Wunde" && <Col span={12}>
                          <Form.Item
                            {...field}
                            label="Produktgruppe"
                            name={[field.name, 'Unterpunkt1']}
                          >
                            <Select  disabled={ !(categorie0[index]) } onSelect={ (v) => setCategorie1({ ...categorie1, [index]: v }) }>
                              { (hauptkategorie[index] && categorie0[index]) ? Object.keys((categoryMappings[hauptkategorie[index]] || {} )[categorie0[index]] || {}).map((cat) => {
                                                              return <Option value={cat}>{cat}</Option>
                                                            }) : null }
                            </Select>
                          </Form.Item>
                        </Col>}
                        { (hauptkategorie[index] === "Wunde"  && categorie0[index] && Object.keys((((categoryMappings[hauptkategorie[index]] || {} )[categorie0[index]] || {})[categorie1[index]] || [])).length > 0) && <Col span={12}>
                          <Form.Item
                            {...field}
                            label="Produktspezifikation"
                            name={[field.name, 'Unterpunkt2']}
                          >
                            <Select disabled={ !((categorie0[index]) && (categorie1[index] )) } onSelect={ (v) => setCategorie2({ ...categorie2, [index]: v }) }>
                                { (hauptkategorie[index] && categorie0[index]) ? Object.keys((((categoryMappings[hauptkategorie[index]] || {} )[categorie0[index]] || {})[categorie1[index]] || [])).map((cat) => {
                                  return <Option value={cat}>{cat}</Option>
                                }) : null }
                            </Select>
                          </Form.Item>
                        </Col>}

                        <Col span={12}>
                          <Form.Item
                            {...field}
                            label="Produktbeschreibung"
                            name={[field.name, 'Produktbeschreibung']}
                          >
                            <ReactQuill style={{background: '#fff' }} placeholder="Produktbeschreibung..." theme="snow" />
                          </Form.Item>
                        </Col>
                        <Divider />
                        <Col span={24}>
                            <h3 style={{color: '#fff' }}>Datenblätter</h3>
                            <br/>
                        </Col>
                        {(((((((veranstaltung || {}).Produkt || {})[index] || {}).Datenblaetter || {}).data  || {})[0] || {}).attributes) && <Col span={12}><PdfViewer  name={veranstaltung.Produkt[index].Datenblaetter.data[0].attributes.name} className={"leafImage leafImage--veranstaltung"} pdf={API_BASE + veranstaltung.Produkt[index].Datenblaetter.data[0].attributes.url}  /> </Col> }
                        <Col span={((((((veranstaltung || {}).Produkt || {})[index] || {}).Produktbild || {}).data || {}).attributes) ? 8 : 8}>
                          <Form.Item label="Datenblätter hochladen" name={[field.name,"Datenblaetter"]}>
                            <Form.Item name={[field.name, "draggerpdf"]} valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                              <Upload.Dragger name="files"
                              crossOrigin={"use-credentials"}
                              data={{
                                ref: "api::veranstaltung.veranstaltung",
                                field: "Datenblaetter",
                              }}
                              action={`${API}/upload`}
                               headers={{ "Authorization": `Bearer ${getToken()}`}}>
                                <p className="ant-upload-drag-icon">
                                  <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Klicken Sie hier oder ziehen Sie Datenblätter in diesen Bereich</p>
                                <p className="ant-upload-hint">PDF Dateien werden unterstützt</p>
                              </Upload.Dragger>
                            </Form.Item>
                          </Form.Item>
                        </Col>


                        { hauptkategorie[index] === "Wunde" &&  <Col span={24}>

                        <Divider />
                            <Form.Item>
                              <Form.List
                            name={[field.name, 'Produktgroesse']}>
                                {(fieldsS, pg) => (
                                  <div key={"p_g_"}>
                                    {fieldsS.map((fieldS) => (
                                      <Row key={fieldS.key} gutter={16}>
                                        <Col span={4}>
                                          <Button type="danger" onClick={() => pg.remove(fieldS.name)}>
                                            <MinusCircleOutlined /> Entfernen
                                          </Button>
                                        </Col>
                                        <Col span={8}>
                                          <Form.Item
                                            {...fieldS}
                                            label="Breite"
                                            name={[fieldS.name, 'Breite']}
                                            fieldKey={[fieldS.fieldKey, 'Breite']}
                                          >
                                            <Select placeholder="Breite" >
                                                { [ {v :'z0-7,5', n: '0 - 7,5 cm'}, {v :'z7,5-12,5', n: '7,5 - 12,5 cm'}, {v :'z12,5-17,5', n: '12,5 - 17,5 cm'}, {v :'z17,5', n: 'über 17,5 cm'}].map((cat) => {
                                                  return <Option value={cat.v} name={cat.n}>{cat.n}</Option>
                                                }) }
                                              </Select>
                                          </Form.Item>
                                          <Form.Item
                                              {...fieldS}
                                              label="Länge"
                                              name={[fieldS.name, 'Laenge']}
                                              fieldKey={[fieldS.fieldKey, 'Laenge']}
                                            >
                                            <Select placeholder="Länge" >
                                                { [ {v :'z0-7,5', n: '0 - 7,5 cm'}, {v :'z7,5-12,5', n: '7,5 - 12,5 cm'}, {v :'z12,5-17,5', n: '12,5 - 17,5 cm'}, {v :'z17,5', n: 'über 17,5 cm'}].map((cat) => {
                                                  return <Option value={cat.v} name={cat.n}>{cat.n}</Option>
                                                }) }
                                              </Select>
                                            </Form.Item>
                                            <Form.Item
                                                                                        {...fieldS}
                                                                                        label="Genaue Größe"
                                                                                        name={[fieldS.name, 'GenaueGroesse']}
                                                                                        fieldKey={[fieldS.fieldKey, 'GenaueGroesse']}
                                                                                      >
                                                                                        <Input placeholder="Genaue Größe" />
                                                                                      </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                          <Form.Item
                                            {...fieldS}
                                            label="PZN"
                                            name={[fieldS.name, 'PZN']}
                                            fieldKey={[fieldS.fieldKey, 'PZN']}
                                          >
                                            <Input placeholder="PZN" />
                                          </Form.Item>
                                        </Col>
                                      </Row>
                                    ))}
                                    <Form.Item>
                                      <Button type="dashed" onClick={() => pg.add()} block>
                                        Produktgröße hinzufügen
                                      </Button>
                                    </Form.Item>
                                  </div>
                                )}
                              </Form.List>
                            </Form.Item>



                        </Col>}
                        { hauptkategorie[index] === "Wunde" &&  <Col span={12}>
                          <Form.Item
                            {...field}
                            label="Verordnungsfähigkeit / Rezeptierbarkeit"
                            name={[field.name, 'VerordnungsfaehigkeitOderRezeptierbarkeit']}
                            valuePropName="checked"
                          >
                          <Switch
                              checkedChildren="Ja"
                              unCheckedChildren="Nein"
                            />
                          </Form.Item>
                        </Col>}
                        { hauptkategorie[index] === "Wunde" && <Col span={12}>
                          <Form.Item
                            {...field}
                            label="Risikoklassifizierung"
                            name={[field.name, 'Risikoklassifizierung']}
                          >
                            <Select>
                              <Option value="Keine Klassifizierung">Keine Klassifizierung</Option>
                              <Option value="Klasse I ">Klasse I</Option>
                              <Option value="Klasse IIa">Klasse IIa</Option>
                              <Option value="Klasse IIb">Klasse IIb</Option>
                              <Option value="Klasse III">Klasse III</Option>
                            </Select>
                          </Form.Item>
                        </Col> }
                        { hauptkategorie[index] === "Wunde" && <Col span={12}>
                          <Form.Item
                            {...field}
                            label="Wirkstoffhaltig"
                            name={[field.name, 'Wirkstoffhaltig']}
                          >
                            <Select mode="multiple">
                              <Option value="Silber">Silber</Option>
                              <Option value="PHMB">PHMB</Option>
                              <Option value="Iod / Jod">Iod / Jod</Option>
                              <Option value="TLC – NOSF">TLC – NOSF</Option>
                              <Option value="topische Sauerstofftherapie">topische Sauerstofftherapie</Option>
                              <Option value="Medizinischer Honig">Medizinischer Honig</Option>
                              <Option value="Ibuprofen">Ibuprofen</Option>
                              <Option value="Octenidin">Octenidin</Option>
                              <Option value="Phenoxyethanol">Phenoxyethanol</Option>
                              <Option value="Hyaluronsaeure">Hyaluronsäure</Option>
                            </Select>
                          </Form.Item>
                        </Col> }

                        { hauptkategorie[index] === "Wunde" && <Col span={12}>
                          <Form.Item
                            {...field}
                            label="Exsudation"
                            name={[field.name, 'Exsudation']}
                          >
                            <Select mode="multiple">
                              <Option value="wenig">wenig</Option>
                              <Option value="mittel">mittel</Option>
                              <Option value="viel">viel</Option>
                            </Select>
                          </Form.Item>
                        </Col> }
                        { hauptkategorie[index] === "Wunde" && <Col span={12}>
                          <Form.Item
                            {...field}
                            label="Haftrand"
                            name={[field.name, 'Haftrand']}
                          >
                            <Select>
                              <Option value="Ohne Rand">Ohne Rand</Option>
                              <Option value="Mit Rand">Mit Rand</Option>
                            </Select>
                          </Form.Item>
                        </Col> }
                        { hauptkategorie[index] === "Wunde" && <Col span={12}>
                          <Form.Item
                            {...field}
                            label="Kombination"
                            name={[field.name, 'Kombination']}
                          >
                            <Select mode="multiple">
                              <Option value="Nein">Nein</Option>
                              <Option value="Alginat">Alginat</Option>
                              <Option value="Schaumverband">Schaumverband</Option>
                              <Option value="Hydrokolloid">Hydrokolloid</Option>
                              <Option value="Hydrogel">Hydrogel</Option>
                              <Option value="Hydrofaser">Hydrofaser</Option>
                              <Option value="Semipermeable Wundfolie">Semipermeable Wundfolie</Option>
                              <Option value="Aktivkohle">Aktivkohle</Option>
                              <Option value="Cavity">Cavity</Option>
                              <Option value="Saugkompresse / Superabsorber">Saugkompresse / Superabsorber</Option>
                              <Option value="Distanzgitter">Distanzgitter</Option>
                              <Option value="Kollagen">Kollagen</Option>
                              <Option value="Hydrophobe Auflage">Hydrophobe Auflage</Option>
                              <Option value="Hydroaktive Auflage">Hydroaktive Auflage</Option>
                              <Option value="Sonstiges">Sonstiges</Option>
                              <Option value="Konservierte Wundspüllösung">Konservierte Wundspüllösung</Option>
                              <Option value="Antiseptikum">Antiseptikum</Option>
                              <Option value="Medizinischer Hautschutz">Medizinischer Hautschutz</Option>
                              <Option value="Hautpflege">Hautpflege</Option>
                              <Option value="Schlauchverband">Schlauchverband</Option>
                              <Option value="Polsterbinde">Polsterbinde</Option>
                              <Option value="Kurzzugbinden">Kurzzugbinden</Option>
                              <Option value="Mehrkomponentensystem">Mehrkomponentensystem</Option>
                              <Option value="Kompressionsbandage">Kompressionsbandage</Option>
                            </Select>
                          </Form.Item>
                        </Col> }

                        { hauptkategorie[index] === "Wunde" && <Col span={12}>
                          <Form.Item
                            {...field}
                            label="Indikationen"
                            name={[field.name, 'Indikationen']}
                          >
                            <Input.TextArea />
                          </Form.Item>
                        </Col> }
                        { hauptkategorie[index] === "Wunde" && <Col span={12}>
                          <Form.Item
                            {...field}
                            label="Kontraindikationen"
                            name={[field.name, 'Kontraindikationen']}
                          >
                            <Input.TextArea />
                          </Form.Item>
                        </Col> }
                        <Col span={12}>
                          <Form.Item
                            {...field}
                            label="Anwendungshinweise"
                            name={[field.name, 'Anwendungshinweise']}
                          >
                            <Input.TextArea />
                          </Form.Item>
                        </Col>

                        { (hauptkategorie[index] === "Ernährung" && (categorie0[index] === "Trink- und Aufbaunahrung" || categorie0[index] === "Sondennahrung")) && <Col span={12}>
                          <Form.Item
                            {...field}
                            label="Indikation / Erkrankung"
                            name={[field.name, 'IndikationOderErkrankung']}
                          >
                            <Select mode="multiple">
                                  { (hauptkategorie[index] && categorie0[index]) ? Object.keys(categoryMappings[hauptkategorie[index]][categorie0[index]]).map((cat) => {
                                    return <Option value={cat}>{cat}</Option>
                                  }) : null }
                            </Select>
                          </Form.Item>
                        </Col>}
                        { (hauptkategorie[index] === "Ernährung" && (categorie0[index] === "Trink- und Aufbaunahrung" || categorie0[index] === "Sondennahrung")) && <Col span={12}>
                          <Form.Item
                            {...field}
                            label="Produktmerkmale"
                            name={[field.name, 'Produktmerkmale']}
                          >
                            <Select mode="multiple">
                              <Select.Option value="Laktosefrei">Laktosefrei</Select.Option>
                              <Select.Option value="Mit Ballaststoffen">Mit Ballaststoffen</Select.Option>
                              <Select.Option value="Ohne Ballaststoffen">Ohne Ballaststoffen</Select.Option>
                              <Select.Option value="Glutenfrei">Glutenfrei</Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>}
                        { (hauptkategorie[index] === "Ernährung" && (categorie0[index] === "Trink- und Aufbaunahrung")) && <Col span={12}>
                          <Form.Item
                            {...field}
                            label="Darreichungsform"
                            name={[field.name, 'Darreichungsform']}
                          >
                            <Select mode="multiple">
                              <Select.Option value="Flüssig">Flüssig</Select.Option>
                              <Select.Option value="Dickflüssig (sirupartig/IDDSI Stufe 2)">Dickflüssig (sirupartig/IDDSI Stufe 2)</Select.Option>
                              <Select.Option value="Zum Löffeln">Zum Löffeln</Select.Option>
                              <Select.Option value="Pulver">Pulver</Select.Option>
                            </Select>
                          </Form.Item>
                        </Col> }
                        { (hauptkategorie[index] === "Ernährung" && (categorie0[index] === "Trink- und Aufbaunahrung")) && <Col span={12}>
                          <Form.Item
                            {...field}
                            label="Geschmacksrichtung"
                            name={[field.name, 'Geschmacksrichtung']}
                          >
                            <Select mode="multiple">
                              <Select.Option value="Citrus-frisch">Citrus-frisch</Select.Option>
                              <Select.Option value="Fruchtig-frisch">Fruchtig-frisch</Select.Option>
                              <Select.Option value="Joghurt-frisch">Joghurt-frisch</Select.Option>
                              <Select.Option value="Herzhaft">Herzhaft</Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>}
                         { (hauptkategorie[index] === "Ernährung" && (categorie0[index] === "Sondennahrung")) && <Col span={12}>
                          <Form.Item
                            {...field}
                            label="Energiegehalt"
                            name={[field.name, 'Energiegehalt']}
                          >
                            <Select>
                              <Select.Option value="lesser1,2 kcal">{'< 1,2 kcal'}</Select.Option>
                              <Select.Option value="greater1,2-1,9 kcal">{'> 1,2-1,9 kcal'}</Select.Option>
                              <Select.Option value="greater2 kcal">{'> 2 kcal'}</Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>}
                        { hauptkategorie[index] === "Ernährung" && <Col span={12}>
                          <Form.Item
                            {...field}
                            label="Verabreichungsart"
                            name={[field.name, 'Verabreichungsart']}
                          >
                            <Select mode="multiple">
                              <Select.Option value="Bolusapplikation">Bolusapplikation</Select.Option>
                              <Select.Option value="Schwerkraftapplikation">Schwerkraftapplikation</Select.Option>
                              <Select.Option value="Pumpenapplikation">Pumpenapplikation</Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>}
                         { (hauptkategorie[index] === "Ernährung" && (categorie0[index] === "Applikationstechnik")) && <Col span={12}>
                          <Form.Item
                            {...field}
                            label="Nahrungsbehältnis"
                            name={[field.name, 'Nahrungsbehaeltnis']}
                          >
                            <Select mode="multiple">
                              <Select.Option value="Flasche (Weithals)">Flasche (Weithals)</Select.Option>
                              <Select.Option value="Nahrungsbeutel mit ENPlus-Anschluss">Nahrungsbeutel mit ENPlus-Anschluss</Select.Option>
                              <Select.Option value="Rucksack">Rucksack</Select.Option>
                              <Select.Option value="Beutel mit integriertem Überleitsystem">Beutel mit integriertem Überleitsystem</Select.Option>
                            </Select>
                          </Form.Item>
                        </Col> }
                        { (hauptkategorie[index] === "Ernährung" && (categorie0[index] === "Applikationstechnik")) && <Col span={12}>
                          <Form.Item
                            {...field}
                            label="Ernährungspumpe"
                            name={[field.name, 'Ernaehrungspumpe']}
                          >
                            <Select mode="multiple">
                              <Select.Option value="Amica® (Fresenius)">Amica® (Fresenius)</Select.Option>
                              <Select.Option value="Flocare infinity® (Nutricia)">Flocare infinity® (Nutricia)</Select.Option>
                              <Select.Option value="Compat Ella® (GHD)">Compat Ella® (GHD)</Select.Option>
                              <Select.Option value="Enteroport® Puls (BBraun)">Enteroport® Puls (BBraun)</Select.Option>
                              <Select.Option value="Infusiomat® Space® (BBraun)">Infusiomat® Space® (BBraun)</Select.Option>
                            </Select>
                          </Form.Item>
                        </Col> }
                        <Col style={{ textAlign: 'left' }} span={24}>
                          <Button  type="danger" onClick={() => remove(field.name)}>
                            Produkt entfernen
                          </Button>
                        </Col>
                      </Row>
                    ))}
                    <Form.Item style={{ width: '100%', paddingTop: '2em' }}>
                        <Button
                          type="dashed"
                          onClick={() => {
                           const isDenied = fields.length > 9 && selectedSubTitle !== "Hersteller: Premium";
                           if (!isDenied) {
                                add()
                           } else {
                                alert("Nur Premium Hersteller Abononments können mehr als 10 Produkte hinzufügen");
                           }
                           }}

                          icon={<PlusOutlined />}
                        >
                          Produkt hinzufügen
                        </Button>
                    </Form.Item>
                </div>
                )}
              </Form.List>
              </div>

            </Col>

        <Divider />

            <Col span={16}>
              <Form.Item
                label={<span  style={{fontSize: '20px', fontWeight: 800}}>Profil veröffentlichen</span>}
                name="Published"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            { selectedSubTitle === "Hersteller: Premium" && <Col span={16}>
              <Form.Item
                label={<span  style={{fontSize: '20px', fontWeight: 400}}>Fokussiere Profil</span>}
                name="Fokusiere"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col> }


          </Row>
          <Row justify="end">
            <Col>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Speichern
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default HerstellerProfile;
