import React, { Component, useContext } from 'react';
import { Row, Col, Rate,Button, Form, Input, Select, TreeSelect, Checkbox, DatePicker } from 'antd';

import ReactDOM from 'react-dom';
import { Link, withRouter } from 'react-router-dom';
import { actions, Table, Pagination }  from 'react-data-components';
import 'react-data-components/css/table-twbs.css';
import {AuthContext} from '../context/AuthContext';
import Autocomplete from './Autocomplete';
import DataTable from './Table/CustomDataTable';
import Header from './Header/index';
import TopPicks from './Picks';
import Collections from './Collections';
import Media from './Media';
import Footer from './footer';
import ExperienceCard from './ExperienceCard';
import './Styles/new-york.css';
import { API_BASE } from "../constant";
import { calculateDistance } from "../helpers";
import { CollectionCard } from './Collections';
import { Modal } from 'antd';

const { RangePicker } = DatePicker;
const { Option } = Select;

const desc = ['absolut nicht empfehlendswert', 'nicht empfehlendswert', 'befriedigend', 'empfehlendswert', 'sehr empfehlendswert'];

         /*<Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                    <Form.Item label="Teilnehmeranzahl" name="Teilnehmeranzahl">
                      <Select
                                            mode="multiple"
                                            allowClear
                                            style={{
                                              width: '100%',
                                            }}
                                            placeholder="Teilnehmeranzahl">
            <Option value="z0-20">unter 20</Option>
             <Option value="z21-50">unter 50</Option>
             <Option value="z51-100">unter 100</Option>
             <Option value="z101-150">unter 150</Option>
             <Option value="z151-300">unter 300</Option>
             <Option value="z301-500">unter 500</Option>
             <Option value="züber 550">über 500</Option>

                                    </Select>
                    </Form.Item>
                  </Col>*/
const info = () => {
  Modal.info({
    title: 'Willkommen bei der Referentensuche!',
    content: (
      <div style={{ marginBottom: -16 }}>
        Benutzen Sie die Filterfunktionen, um schnellstmöglich zu einem passenden Treffer zu kommen.
      </div>
    ),
    onOk() { localStorage.setItem("pp_filterAkkn", true); },
  });
};

const columns = [
  { title: '', prop: 'Bild', render: (Bild) => <div className="city-card-img city-card-img--table city-card-img--table--referenten" style={{ backgroundImage: `url(${API_BASE + ((((Bild || {}).data || {}).attributes || {}).url || "/uploads/pmp_ac1da8c4a1.png")})` }}></div> },
  { title: 'Name', prop: 'Name', render : (Name) => <span className="label">{Name}</span> },
{ title: 'Qualifikation', prop: 'Zusatzqualifikation', render : (name) => <span className="label">{name}</span> },
  { title: 'Ort', prop: 'Stadt', render : (city) => <span className="label">{city}</span> },
//  { title: 'Bewertung', prop: 'Bewertung',
//  render : (points) => <Rate style={{ fontSize: 16, paddingRight: 4 }} tooltips={desc} disabled value={Math.round(points)} /> },
];

const columnsExtended = [ ...columns,
/*{ title: 'Einsatzgebiet', prop: 'Einsatzgebiet', render : (name) => <span className="label">{name}</span> },*/
{ title: 'Themengebiete', prop: 'Themengebiete', render : (name) => <span className="label">{name}</span> },
{ title: 'Honorar', prop: 'HonorarvorstellungProUnterrichtseinheit', render : (name) => <span className="label">{name ? <span>{name.substr(1)}  € pro Einheit</span> : null}</span> },
];


class SelectableTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: '',
    };
  }

  selectRow = (row) => {
    this.setState({ selected: row.id });
  };

  deSelectRow = (row) => {
    this.setState({ selected: '' });
  };

  buildRowOptions = (row) => {
    return {
        onClick: () => {
            this.props.history.push(`/referenten/${row.id}`);
        },
//      onMouseOver: () => this.selectRow(row),
//      onMouseOut: () => this.deSelectRow(row),
      className: this.state.selected === row.id ? 'active-row' : null,
    };
  };

  render() {
    return (
      <DataTable buildRowOptions={this.buildRowOptions} {...this.props} />
    );
  }
}

class Veranstaltungen extends Component {
  constructor() {
    super()
    this.state = {
      lat: 51.505,
      lng: -0.09,
      zoom: 13,
      selected: '',
      filterObj: {},
      vOrt: false,
      advancedFilterShown: false,
      filteredVeranstaltungenData: []
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    let typingTimer;

    setTimeout(() => {
    const inputField = document.getElementById("veranstaltungsort");
    if (!inputField) return;

    if (localStorage.getItem("pp_filterAkkn") != "true") info();

    inputField.addEventListener('input', () => {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(() => {
            // Perform your action here, e.g., make an API call
            console.log("User stopped typing, performing action...");

            const veranstaltungsort = document.getElementById("veranstaltungsort").value;

            if (!veranstaltungsort) { this.setState({vOrt: false}); return; }

            fetch(`https://geocode.maps.co/search?q=${veranstaltungsort},Germany&api_key=65d4b23015fec727948775azl6467b9`)
                    .then((response) => response.json())
                    .then((data) => {
                    // update the marker based on the response
                    if (((data || {})[0] || {}).lat) {
                        this.setState({ vOrt : [data[0].lat, data[0].lon] });
                        this.filterByObj();
                    } else {
                        this.setState({ vOrt : false });
                        this.filterByObj();
                    }
                    });

        }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)
    });

    }, 4000)

    this.setState({ filteredVeranstaltungenData: this.props.veranstaltungenData.filter( v =>  v.type === "Referent" && v.Published === true) });
  }

  componentDidUpdate(prevProps, prevState) {
  // check filterAdvancedHighlighted
    if (prevProps.filterAdvancedHighlighted !== this.props.filterAdvancedHighlighted && !this.props.filterAdvancedHighlighted) {
//        return this.setState({ filteredVeranstaltungenData: this.props.veranstaltungenData.filter( v =>  v.type === "Referent") });
    }

    if ((prevProps.veranstaltungenData || []).length !== (this.props.veranstaltungenData || []).length) {
        this.setState({ filteredVeranstaltungenData: this.props.veranstaltungenData.filter( v =>  v.type === "Referent" && v.Published === true) });
    }
    if (prevState.filterObj !== this.state.filterObj || (JSON.stringify(prevState.filterObj || {}) !== JSON.stringify(this.state.filterObj || {}))){
        this.filterByObj();
    }

  }

  selectRow = (row) => {
    this.setState({ selected: row.id });
  }

//  buildRowOptions = (row) => { alert(row.name);
//   return {
//     onClick: this.selectRow.bind(this, row),
//     className: this.state.selected === row.id ? 'active' : null
//   }}


  handleRowClick = (rowData) => {
    // Perform actions based on the clicked row data
  };

    updateFilterByObj = (e, v) => {
        this.setState({ filterObj: v });
    }

    handleFilter = (newValue) => {
        this.setState({ filterObj: newValue })
    }

  filterByObj = () => {
    const { veranstaltungenData, filterAdvancedHighlighted } = this.props;
    const { vOrt } = this.state;

    if(!filterAdvancedHighlighted) return;

    const filterObj = this.state.filterObj;
    const filterKeysWithValues = Object.keys(filterObj).filter(key => filterObj[key] !== undefined && filterObj[key] !== null && filterObj[key] !== "" && (filterObj[key] || []).length !== 0)
    const filteredReferentenData = this.props.veranstaltungenData.filter( v =>  v.type === "Referent")
    .filter( v => filterKeysWithValues.every( key => {
        if (vOrt && v["Einsatzgebiet"] && key === "Veranstaltungsort" && (v["Einsatzort"] || []).length === 2) {
            const distance = (v["Einsatzgebiet"].match(/\d+$/)[1] || ((v["Einsatzgebiet"].match(/(\d+)$/) || [])[0] )) * 1000 * (!v["Einsatzgebiet"].match(/(\d+)-(\d+)/) ? 3 : 1);
            return calculateDistance(vOrt, v["Einsatzort"]) < distance;
        }
        if (Array.isArray(filterObj[key]) && Array.isArray(v[key])) {
            if (key === "Terminverfuegbarkeit") {
                const stringDateArray = filterObj[key].map( d => d.toISOString());
                return stringDateArray.some(vk => v[key].includes(vk))
            } else {
                return filterObj[key].some(vk => v[key].includes(vk))
            }
        }

        if (key.indexOf("Honorarvorstellung") > -1 && v[key]) {
            const honorarVorstellungMax = (((filterObj[key] || "").match(/(\d+)$/) || [])[0]);
            const honorarVorstellungMinRef = ((v[key] || "").match(/\d+$/)[1] || ((v[key] || "").match(/(\d+)$/) || [])[0]);
           return (+honorarVorstellungMax >= +honorarVorstellungMinRef);
        }



        if (typeof filterObj[key] === 'string' && typeof v[key] === 'string') {
            return v[key].toUpperCase().indexOf(filterObj[key].toUpperCase()) > -1;
        }

        return v[key] === filterObj[key];
    }));

    // wait for Veranstaltungsort to stop typing


    this.setState({ filteredVeranstaltungenData: filteredReferentenData });

  };

  render() {
      const { veranstaltungenData, filterAdvancedHighlighted, form } = this.props;
      const { advancedFilterShown, filteredVeranstaltungenData } = this.state;
    return (
      <>
<div className={"search-bar-div search-bar-div--filter " + (filterAdvancedHighlighted ? "search-bar-div--active" : "search-bar-div--inactive")}> <div style={{ padding: '2em', width: '80%', height: 'auto' }} className="select-city-large ">
<FilterWrapper form={form} updateFilterByObj={this.updateFilterByObj} filterObj={this.state.filterObj} setFilter={this.handleFilter} />
 </div></div>
  { filteredVeranstaltungenData.length > 0 && <CitySection
      key={1}
      headline={'Referenten im Fokus'}
      description={

        <div className="cards-wrapper">

           { filteredVeranstaltungenData.filter( v =>  v.Fokusiere === true && v.Published === true ).length > 0 ? (
          filteredVeranstaltungenData.filter( v =>  v.Fokusiere === true && v.Published === true ).map(({ Bild, id, Name, Vorname, description }) => (
            <Link
                              to={{ pathname: `/referenten/${id}` }}
                              key={'v_sa' + id}
                              className="link_sa view-all"
                            >
              <CollectionCard key={id} url={(((Bild || {}).data || {}).attributes)  ? (API_BASE + Bild.data.attributes.url) : "/pmp.png"} description={Name + ", " + Vorname} /></Link>
            ))) : <div>Keine Referenten gefunden, die dem Filter entsprechen...</div>}
        </div>
     }
    /> }

  <CitySection
      key={2}
      headline={'Referenten'}
      description={
      <SelectableTable
          locale="de"
          keys="name"
          history={this.props.history}
          columns={columnsExtended}
          initialData={filteredVeranstaltungenData}
          initialPageLength={5}
          initialSortBy={{ prop: 'date', order: 'descending' }}
          pageLengthOptions={[ 5, 20, 50 ]}
        />
     }
    />


      </>
    );
  }
}


const FilterWrapper = ({ form, updateFilterByObj, filterObj, setFilter }) => {
const { user, sub, setSub, availableSubs } = useContext(AuthContext);
const selectedSubTitle = (sub ? ((availableSubs || []).find((availableSub) => (availableSub || {}).stripePlanId === ((sub || {}).plan || {}).id) || {}).title || "" : "");

    return ( !(user && sub && selectedSubTitle.indexOf("Veranstalter") > -1)  ?  <p>Um nach Referenten filtern zu können, <Link to="/signin">melden</Link> Sie sich bitte an.</p>
  : <Form
        form={form}
       name="myForm"
       initialValues={{
       }}
       onValuesChange={(e,v) => updateFilterByObj(e,v) }
     >

       <Row gutter={24}>
         <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
           <Form.Item label="Terminverfügbarkeit" name="Terminverfuegbarkeit">
            <DatePicker
                                      multiple
                                      maxTagCount="responsive"
                                      size="large"
                                    />
           </Form.Item>
         </Col>
         <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
           <Form.Item label="Veranstaltungsort" name="Veranstaltungsort">
             <Input id="veranstaltungsort" placeholder="Ort oder PLZ"/>
           </Form.Item>
         </Col>
       </Row>
       <Row gutter={24}>
         <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
           <Form.Item label="Grundqualifikation" name="Grundqualifikation">
             <Select placeholder="Grundqualifikation" mode="multiple" options={
                           [
                               {
                                   "value": "Apotheker",
                                   "label": "Apotheker"
                               },
                               {
                                   "value": "Ärzte (Humanmedizin)",
                                   "label": "Ärzte (Humanmedizin)"
                               },
                               {
                                   "value": "Heilpraktiker",
                                   "label": "Heilpraktiker"
                               },
                               {
                                   "value": "Heilerziehungspfleger",
                                   "label": "Heilerziehungspfleger"
                               },
                               {
                                   "value": "Medizinische Fachangestellte (MFA)",
                                   "label": "Medizinische Fachangestellte (MFA)"
                               },
                               {
                                   "value": "Operationstechnische Assistenten (OTA)",
                                   "label": "Operationstechnische Assistenten (OTA)"
                               },
                               {
                                   "value": "Gesundheits- und Krankenpfleger",
                                   "label": "Gesundheits- und Krankenpfleger"
                               },
                               {
                                   "value": "Gesundheits- und Kinderkrankenpfleger",
                                   "label": "Gesundheits- und Kinderkrankenpfleger"
                               },
                               {
                                   "value": "Altenpfleger",
                                   "label": "Altenpfleger"
                               },
                               {
                                   "value": "Podologen",
                                   "label": "Podologen"
                               },
                               {
                                   "value": "Physiotherapeuten mit Zusatzqualifikation Lymphtherapeut",
                                   "label": "Physiotherapeuten mit Zusatzqualifikation Lymphtherapeut"
                               }
                           ]} defaultValue={[]} />
           </Form.Item>
         </Col>
         <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
           <Form.Item label="Zusatzqualifikation" name="Zusatzqualifikation">
             <Select placeholder="Zusatzqualifikation" mode="multiple" options={
                           [
                               {
                                   "value": "Wundexperte ICW ®",
                                   "label": "Wundexperte ICW ®"
                               },
                               {
                                   "value": "Ärztlicher Wundexperte ICW ®",
                                   "label": "Ärztlicher Wundexperte ICW ®"
                               },
                               {
                                   "value": "Fachtherapeut Wunde ICW ®",
                                   "label": "Fachtherapeut Wunde ICW ®"
                               },
                               {
                                   "value": "Pflegetherapeut ICW ®",
                                   "label": "Pflegetherapeut ICW ®"
                               },
                               {
                                   "value": "WaCert ®",
                                   "label": "WaCert ®"
                               },
                               {
                                   "value": "WTCert ®",
                                   "label": "WTCert ®"
                               }
                           ]} defaultValue={[]} />
           </Form.Item>
         </Col>
         </Row>
         <Row gutter={24}>
         <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
           <Form.Item label="Honorarpauschale pro Unterrichtseinheit" name="HonorarvorstellungProUnterrichtseinheit">
             <Select >
               <Option value="z0-50">unter 50 €/ UE</Option>
             <Option value="z51-100">unter 100 €/ UE</Option>
             <Option value="z101-150">unter 150 €/ UE</Option>
             <Option value="z150">über 150 €/ UE</Option>
             </Select>
           </Form.Item>
         </Col>
         <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
           <Form.Item label="Honorarpauschale halber Tag" name="HonorarvorstellungPauschaleHalberTag">
             <Select>
               <Option value="zunter 200">unter 200€</Option>
                                             <Option value="z300">unter 300 €</Option>
                                             <Option value="z400">unter 400 €</Option>
                                             <Option value="z500">unter 500 €</Option>
                                             <Option value="züber 500">über 500 €</Option>
             </Select>
           </Form.Item>
         </Col>
         <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
           <Form.Item label="Honorarpauschale ganzer Tag" name="HonorarvorstellungPauschaleGanzerTag">
             <Select>
               <Option value="zunter 400">unter 400€</Option>
             <Option value="z500">unter 500 €</Option>
             <Option value="z600">unter 600 €</Option>
             <Option value="z700">unter 700 €</Option>
             <Option value="z800">unter 800 €</Option>
             <Option value="züber 800">über 800 €</Option>
             </Select>
           </Form.Item>
         </Col>
         </Row>
         <Row gutter={24}>
         <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
           <Form.Item label="Fortbildungsthema WundexperteICW" name="FortbildungsinhalteWundexperteICW">
             <Select
                                 mode="multiple"
                                 allowClear
                                 style={{
                                   width: '100%',
                                 }}
                                 placeholder="Fortbildungsthema Wundexperte ICW ®"
                                 options={[
                                            { "label": "ICW und Kurskonzept (Wundexperte ICW ®)", "value": "ICW und Kurskonzept (Wundexperte ICW ®)" },
                                            { "label": "Haut/Hautpflege (Wundexperte ICW ®)", "value": "Haut/Hautpflege (Wundexperte ICW ®)" },
                                            { "label": "Wundarten und Wundheilung (Wundexperte ICW ®)", "value": "Wundarten und Wundheilung (Wundexperte ICW ®)" },
                                            { "label": "Schmerz (Wundexperte ICW ®)", "value": "Schmerz (Wundexperte ICW ®)" },
                                            { "label": "Hospitation und Leistungsnachweise (Wundexperte ICW ®)", "value": "Hospitation und Leistungsnachweise (Wundexperte ICW ®)" },
                                            { "label": "Hygiene in der Wundversorgung (Wundexperte ICW ®)", "value": "Hygiene in der Wundversorgung (Wundexperte ICW ®)" },
                                            { "label": "Wundbeurteilung und Wunddokumentation (Wundexperte ICW ®)", "value": "Wundbeurteilung und Wunddokumentation (Wundexperte ICW ®)" },
                                            { "label": "Expertenstandard Pflege von Menschen mit Chronischen Wunden (DNQP) (Wundexperte ICW ®)", "value": "Expertenstandard Pflege von Menschen mit Chronischen Wunden (DNQP) (Wundexperte ICW ®)" },
                                            { "label": "Ulcus cruris Prophylaxe/Behandlung (Wundexperte ICW ®)", "value": "Ulcus cruris Prophylaxe/Behandlung (Wundexperte ICW ®)" },
                                            { "label": "Dekubitus/Prophylaxe und Therapie (Wundexperte ICW ®)", "value": "Dekubitus/Prophylaxe und Therapie (Wundexperte ICW ®)" },
                                            { "label": "Diabetisches Fußsyndrom (DFS)/Prophylaxe und Therapie (Wundexperte ICW ®)", "value": "Diabetisches Fußsyndrom (DFS)/Prophylaxe und Therapie (Wundexperte ICW ®)" },
                                            { "label": "Grundsätze Wundversorgung und Wundauflagen (Wundexperte ICW ®)", "value": "Grundsätze Wundversorgung und Wundauflagen (Wundexperte ICW ®)" },
                                            { "label": "Wundreinigung/Wundspülung (Wundexperte ICW ®)", "value": "Wundreinigung/Wundspülung (Wundexperte ICW ®)" },
                                            { "label": "Infektmanagement (Wundexperte ICW ®)", "value": "Infektmanagement (Wundexperte ICW ®)" },
                                            { "label": "Fallmanagement (Wundexperte ICW ®)", "value": "Fallmanagement (Wundexperte ICW ®)" },
                                            { "label": "Finanzierung (Wundexperte ICW ®)", "value": "Finanzierung (Wundexperte ICW ®)" },
                                            { "label": "Rechtliche Aspekte der Wundversorgung Edukation (Wundexperte ICW ®)", "value": "Rechtliche Aspekte der Wundversorgung Edukation (Wundexperte ICW ®)" },
                                            { "label": "Ernährung (Wundexperte ICW ®)", "value": "Ernährung (Wundexperte ICW ®)" },
                                            { "label": "Vertiefungsstunde (Wundexperte ICW ®)", "value": "Vertiefungsstunde (Wundexperte ICW ®)" }
                                          ]
             }
                               />
           </Form.Item>
         </Col>
         <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
           <Form.Item label="Fortbildungsthema Fachtherapeut Wunde ICW" name="FortbildungsinhalteFachtherapeutWundeICW">
             <TreeSelect
                   showSearch
                   style={{ width: '100%' }}
                   dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                   placeholder="Fortbildungsthema Fachtherapeut Wunde ICW ®"
                   allowClear
                   multiple
                   treeDefaultExpandAll
                   treeData={[
                               {
                                 "value": "KURSEINFÜHRUNG (Fachtherapeut Wunde ICW ®)",
                                 "title": "KURSEINFÜHRUNG (Fachtherapeut Wunde ICW ®)",
                                 "children": []
                               },
                               {
                                 "value": "CHRONISCHE WUNDEN PATHOPHYSIOLOGIE UND Diagnostik (Fachtherapeut Wunde ICW ®)",
                                 "title": "CHRONISCHE WUNDEN PATHOPHYSIOLOGIE UND Diagnostik (Fachtherapeut Wunde ICW ®)",
                                 "children": []
                               },
                               {
                                 "value": "WUNDASSESSMENT UND WUNDDOKUMENTATION (Fachtherapeut Wunde ICW ®)",
                                 "title": "WUNDASSESSMENT UND WUNDDOKUMENTATION (Fachtherapeut Wunde ICW ®)",
                                 "children": []
                               },
                               {
                                 "value": "LYMPHATISCHE ERKRANKUNGEN (Fachtherapeut Wunde ICW ®)",
                                 "title": "LYMPHATISCHE ERKRANKUNGEN (Fachtherapeut Wunde ICW ®)",
                                 "children": []
                               },
                               {
                                 "value": "KOMPLEXE WUNDSITUATIONEN/WUNDREINIGUNG/HAUTSCHÄDEN (Fachtherapeut Wunde ICW ®)",
                                 "title": "KOMPLEXE WUNDSITUATIONEN/WUNDREINIGUNG/HAUTSCHÄDEN (Fachtherapeut Wunde ICW ®)",
                                 "children": []
                               },
                               {
                                 "value": "TUMORWUNDEN/ PALLIATIVE VERSORGUNG (Fachtherapeut Wunde ICW ®)",
                                 "title": "TUMORWUNDEN/ PALLIATIVE VERSORGUNG (Fachtherapeut Wunde ICW ®)",
                                 "children": []
                               },
                               {
                                 "value": "SCHMERZ (Fachtherapeut Wunde ICW ®)",
                                 "title": "SCHMERZ (Fachtherapeut Wunde ICW ®)",
                                 "children": []
                               },
                               {
                                 "value": "GESUNDHEITSÖKONOMIE (Fachtherapeut Wunde ICW ®)",
                                 "title": "GESUNDHEITSÖKONOMIE (Fachtherapeut Wunde ICW ®)",
                                 "children": []
                               },
                               {
                                 "value": "HYGIENE (Fachtherapeut Wunde ICW ®)",
                                 "title": "HYGIENE (Fachtherapeut Wunde ICW ®)",
                                 "children": []
                               },
                               {
                                 "value": "ULCUS CRURIS (Fachtherapeut Wunde ICW ®)",
                                 "title": "ULCUS CRURIS (Fachtherapeut Wunde ICW ®)",
                                 "children": []
                               },
                               {
                                 "value": "Grundlagen Gefäßsystem, CVI und Differentialdiagnosen",
                                 "title": "Grundlagen Gefäßsystem, CVI und Differentialdiagnosen",
                                 "children": [
                                   {
                                     "value": "a) Interventionen bei venösem Ulcus und Lokaltherapie",
                                     "title": "a) Interventionen bei venösem Ulcus und Lokaltherapie",
                                     "children": []
                                   },
                                   {
                                     "value": "b) Ulcus cruris arteriosum/mixtum",
                                     "title": "b) Ulcus cruris arteriosum/mixtum",
                                     "children": []
                                   },
                                   {
                                     "value": "c) Amputation",
                                     "title": "c) Amputation",
                                     "children": []
                                   },
                                   {
                                     "value": "d) Kompression und Kommunikation",
                                     "title": "d) Kompression und Kommunikation",
                                     "children": []
                                   }
                                 ]
                               },
                               {
                                 "value": "DEKUBITUS (Fachtherapeut Wunde ICW ®)",
                                 "title": "DEKUBITUS (Fachtherapeut Wunde ICW ®)",
                                 "children": [
                                   {
                                     "value": "a) Grundlagen und Differentialdiagnostik",
                                     "title": "a) Grundlagen und Differentialdiagnostik",
                                     "children": []
                                   },
                                   {
                                     "value": "b) Hilfsmittel und Kommunikation",
                                     "title": "b) Hilfsmittel und Kommunikation",
                                     "children": []
                                   },
                                   {
                                     "value": "c) Therapie und Schwerpunkte Lokaltherapie",
                                     "title": "c) Therapie und Schwerpunkte Lokaltherapie",
                                     "children": []
                                   }
                                 ]
                               },
                               {
                                 "value": "DIABETISCHES FUßSYNDROM (Fachtherapeut Wunde ICW ®)",
                                 "title": "DIABETISCHES FUßSYNDROM (Fachtherapeut Wunde ICW ®)",
                                 "children": [
                                   {
                                     "value": "a) Grundlagen und Diagnostik",
                                     "title": "a) Grundlagen und Diagnostik",
                                     "children": []
                                   },
                                   {
                                     "value": "b) Unterstützende Maßnahmen und Kommunikation",
                                     "title": "b) Unterstützende Maßnahmen und Kommunikation",
                                     "children": []
                                   },
                                   {
                                     "value": "c) Infektionsmanagement und lokale Wundtherapie",
                                     "title": "c) Infektionsmanagement und lokale Wundtherapie",
                                     "children": []
                                   }
                                 ]
                               },
                               {
                                 "value": "THERMISCH BEDINGTE WUNDEN/ NARBEN (Fachtherapeut Wunde ICW ®)",
                                 "title": "THERMISCH BEDINGTE WUNDEN/ NARBEN (Fachtherapeut Wunde ICW ®)",
                                 "children": [
                                   {
                                     "value": "a) Thermisch bedingte Wunden",
                                     "title": "a) Thermisch bedingte Wunden",
                                     "children": []
                                   },
                                   {
                                     "value": "b) Narben",
                                     "title": "b) Narben",
                                     "children": []
                                   }
                                 ]
                               },
                               {
                                 "value": "DERMATOLOGISCHE WUNDEN (Fachtherapeut Wunde ICW ®)",
                                 "title": "DERMATOLOGISCHE WUNDEN (Fachtherapeut Wunde ICW ®)",
                                 "children": []
                               },
                               {
                                 "value": "PRÜFUNGSVORBEREITUNG (Fachtherapeut Wunde ICW ®)",
                                 "title": "PRÜFUNGSVORBEREITUNG (Fachtherapeut Wunde ICW ®)",
                                 "children": []
                               },
                               {
                                 "value": "ORGANISATION WUNDMANAGEMENT (Fachtherapeut Wunde ICW ®)",
                                 "title": "ORGANISATION WUNDMANAGEMENT (Fachtherapeut Wunde ICW ®)",
                                 "children": []
                               },
                               {
                                 "value": "LEISTUNGSNACHWEISE (Fachtherapeut Wunde ICW ®)",
                                 "title": "LEISTUNGSNACHWEISE (Fachtherapeut Wunde ICW ®)",
                                 "children": []
                               }
                             ]
             }
                 />
           </Form.Item>
         </Col>
         <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
           <Form.Item label="Fortbildungsthema Ärztlicher Wundexperte ICW" name="FortbildungsinhalteAerztlicherWundexperteICW">
             <Select
                                             mode="multiple"
                                             allowClear
                                             style={{
                                               width: '100%',
                                             }}
                                             placeholder="Fortbildungsthema Ärztlicher Wundexperte ICW ®"
                                             options={
                                             [
                                               {
                                                 "label": "ICW UND KURSKONZEPT SOWIE EINFÜHRUNG UND KENNENLERNEN (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "ICW UND KURSKONZEPT SOWIE EINFÜHRUNG UND KENNENLERNEN (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "PHYSIOLOGIE UND PATHOPHYSIOLOGIE DER WUNDHEILUNG (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "PHYSIOLOGIE UND PATHOPHYSIOLOGIE DER WUNDHEILUNG (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "WUNDBEURTEILUNG UND -DOKUMENTATION (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "WUNDBEURTEILUNG UND -DOKUMENTATION (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "WUNDTHERAPEUTIKA – Systematik (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "WUNDTHERAPEUTIKA – Systematik (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "LOKALTHERAPIE CHRONISCHER WUNDEN - SYSTEMATIK (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "LOKALTHERAPIE CHRONISCHER WUNDEN - SYSTEMATIK (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "EXSUDAT- UND GERUCHSMANAGEMENT SOWIE WUNDRANDSCHUTZ (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "EXSUDAT- UND GERUCHSMANAGEMENT SOWIE WUNDRANDSCHUTZ (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "DÉBRIDEMENT – SYSTEMATIK (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "DÉBRIDEMENT – SYSTEMATIK (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "PHYSIKALISCHE VERFAHREN IN DER WUNDBEHANDLUNG - SYSTEMATIK (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "PHYSIKALISCHE VERFAHREN IN DER WUNDBEHANDLUNG - SYSTEMATIK (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "SPEZIELLE WUNDTHERAPEUTIKA (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "SPEZIELLE WUNDTHERAPEUTIKA (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "EXTERNA INKLUSIVE HAUTPFLEGE (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "EXTERNA INKLUSIVE HAUTPFLEGE (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "PAVK KRANKHEITSBILD UND DIAGNOSTIK ® ABI-MESSUNG (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "PAVK KRANKHEITSBILD UND DIAGNOSTIK ® ABI-MESSUNG (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "PAVK THERAPIE (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "PAVK THERAPIE (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "CVI KRANKHEITSBILD UND DIAGNOSTIK (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "CVI KRANKHEITSBILD UND DIAGNOSTIK (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "CVI THERAPIE (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "CVI THERAPIE (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "KOMPRESSION - SYSTEMATIK UND (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "KOMPRESSION - SYSTEMATIK UND (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "ÖDEME UND DEREN DIFFERENZIALDIAGNOSE (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "ÖDEME UND DEREN DIFFERENZIALDIAGNOSE (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "LYMPHÖDEM PRIMÄR UND SEKUNDÄR - DIAGNOSTIK UND THERAPIEANSÄTZE (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "LYMPHÖDEM PRIMÄR UND SEKUNDÄR - DIAGNOSTIK UND THERAPIEANSÄTZE (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "HYGIENE BEI CHRONISCHEN WUNDEN (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "HYGIENE BEI CHRONISCHEN WUNDEN (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "INFEKTIONSBEKÄMPFUNG – (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "INFEKTIONSBEKÄMPFUNG – (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "DIABETISCHES FUßSYNDROM GRUNDLAGEN UND DIAGNOSTIK (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "DIABETISCHES FUßSYNDROM GRUNDLAGEN UND DIAGNOSTIK (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "DIABETISCHES FUßSYNDROM - THERAPIE INKLUSIVE ENTLASTUNGSVERFAHREN (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "DIABETISCHES FUßSYNDROM - THERAPIE INKLUSIVE ENTLASTUNGSVERFAHREN (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "ÜBERLEITUNGSMANAGEMENT UND INTERPROFESSIONELLES NETZWERK HEIL- UND HILFSMITTEL BEI PATIENTEN MIT CHRONISCHEN WUNDEN (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "ÜBERLEITUNGSMANAGEMENT UND INTERPROFESSIONELLES NETZWERK HEIL- UND HILFSMITTEL BEI PATIENTEN MIT CHRONISCHEN WUNDEN (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "ÜBUNGSSEQUENZ FALLMANAGEMENT (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "ÜBUNGSSEQUENZ FALLMANAGEMENT (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "DEKUBITUS (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "DEKUBITUS (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "PLASTISCH-CHIRURGISCHE THERAPIEOPTIONEN (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "PLASTISCH-CHIRURGISCHE THERAPIEOPTIONEN (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "PALLIATIVE WUNDBEHANDLUNG (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "PALLIATIVE WUNDBEHANDLUNG (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "SCHMERZTHERAPIE (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "SCHMERZTHERAPIE (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "GESUNDHEITSÖKONOMIE (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "GESUNDHEITSÖKONOMIE (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "MEDIZINPRODUKTERECHT (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "MEDIZINPRODUKTERECHT (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "ERNÄHRUNG BEI PATIENTEN MIT CHRONISCHEN WUNDEN (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "ERNÄHRUNG BEI PATIENTEN MIT CHRONISCHEN WUNDEN (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "SELTENE URSACHEN CHRONISCHER WUNDEN (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "SELTENE URSACHEN CHRONISCHER WUNDEN (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "THERMISCHE URSACHEN FÜR WUNDEN (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "THERMISCHE URSACHEN FÜR WUNDEN (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "ÜBUNGSSEQUENZ FALLMANAGEMENT (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "ÜBUNGSSEQUENZ FALLMANAGEMENT (Ärztlicher Wundexperte ICW ®)"
                                               },
                                               {
                                                 "label": "PRÜFUNGSVORBEREITUNG (Ärztlicher Wundexperte ICW ®)",
                                                 "value": "PRÜFUNGSVORBEREITUNG (Ärztlicher Wundexperte ICW ®)"
                                               }
                                             ]

                         }
                                           />
           </Form.Item>
         </Col>
         <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
           <Form.Item label="Fortbildungsthema Pflegetherapeut Wunde ICW" name="FortbildungsinhaltePflegetherapeutWundeICW">
             <Select
             mode="multiple"
             allowClear
             style={{
               width: '100%',
             }}
             placeholder="Fortbildungsthema Wundexperte ICW ®"
             options={[
                        {
                          "label": "SEMINAREINFÜHRUNG (Pflegetherapeut Wunde ICW ®)",
                          "value": "SEMINAREINFÜHRUNG (Pflegetherapeut Wunde ICW ®)"
                        },
                        {
                          "label": "WISSENSCHAFTLICH ORIENTIERTES ARBEITEN (THEMENBLOCK 1) (Pflegetherapeut Wunde ICW ®)",
                          "value": "WISSENSCHAFTLICH ORIENTIERTES ARBEITEN (THEMENBLOCK 1) (Pflegetherapeut Wunde ICW ®)"
                        },
                        {
                          "label": "EXPERTENSTANDARD (THEMENBLOCK 2) (Pflegetherapeut Wunde ICW ®)",
                          "value": "EXPERTENSTANDARD (THEMENBLOCK 2) (Pflegetherapeut Wunde ICW ®)"
                        },
                        {
                          "label": "LEBENSQUALITÄT, SELBSTPFLEGEMANAGEMENT UND EDUKATION (THEMENBLOCK 3) (Pflegetherapeut Wunde ICW ®)",
                          "value": "LEBENSQUALITÄT, SELBSTPFLEGEMANAGEMENT UND EDUKATION (THEMENBLOCK 3) (Pflegetherapeut Wunde ICW ®)"
                        },
                        {
                          "label": "CASEMANAGEMENT (THEMENBLOCK 4) (Pflegetherapeut Wunde ICW ®)",
                          "value": "CASEMANAGEMENT (THEMENBLOCK 4) (Pflegetherapeut Wunde ICW ®)"
                        },
                        {
                          "label": "PROJEKTMANAGEMENT (THEMENBLOCK 5) (Pflegetherapeut Wunde ICW ®)",
                          "value": "PROJEKTMANAGEMENT (THEMENBLOCK 5) (Pflegetherapeut Wunde ICW ®)"
                        },
                        {
                          "label": "GRUNDLAGEN DES SACHVERSTÄNDIGEN-GUTACHTENS (THEMENBLOCK 6) (Pflegetherapeut Wunde ICW ®)",
                          "value": "GRUNDLAGEN DES SACHVERSTÄNDIGEN-GUTACHTENS (THEMENBLOCK 6) (Pflegetherapeut Wunde ICW ®)"
                        },
                        {
                          "label": "PÄDAGOGISCHE AUFGABEN (THEMENBLOCK 7) (Pflegetherapeut Wunde ICW ®)",
                          "value": "PÄDAGOGISCHE AUFGABEN (THEMENBLOCK 7) (Pflegetherapeut Wunde ICW ®)"
                        },
                        {
                          "label": "LERNZIELÜBERPRÜFUNG (THEMENBLOCK 8) (Pflegetherapeut Wunde ICW ®)",
                          "value": "LERNZIELÜBERPRÜFUNG (THEMENBLOCK 8) (Pflegetherapeut Wunde ICW ®)"
                        }
                      ]

                     }
                                       />
           </Form.Item>
         </Col>

       </Row>

  { Object.keys(filterObj).length !== 0 && <Row gutter = {24}>
  <Col>
  <Button ghost
                   type="danger"
                   size="large"
                   onClick={() => {
                     updateFilterByObj(undefined, {});
                     form.resetFields();
                     }}
                 >
                   Filter zurücksetzen
                 </Button>

  </Col></Row> }
     </Form>);
}
const CitySection = ({ headline, description, cardsData }) => {
const { user, sub, setSub, availableSubs } = useContext(AuthContext);
const selectedSubTitle = (sub ? ((availableSubs || []).find((availableSub) => (availableSub || {}).stripePlanId === ((sub || {}).plan || {}).id) || {}).title || "" : "");

return(
  <div className="city-section-wrapper">
    <h2
      style={{
        textAlign: 'left', marginBottom: 8
      }}
    >
      {headline}
    </h2>
    <hr
      style={{
        backgroundColor: '#355fb3',
        width: '75px',
        height: '2px',
        border: 'none',
        marginTop: '0px',
        marginLeft: '0px',
        marginBottom: '10px'
      }}
    />
    <p
      style={{
        color: '#545454',
        fontSize: '15.3px',
        marginTop: '0px',
        textAlign: 'left',
        lineHeight: '20px'
      }}
    >
      { ((user && sub && selectedSubTitle.indexOf("Veranstalter") > -1) || headline !== "Referenten") ? description : <p>Um Referenten einsehen zu können, <Link to="/signin">melden</Link> Sie sich bitte an und schließen Sie ein Veranstalter Abo ab.</p> }
    </p>
    <div className="travel-card-wrapper">
    </div>
  </div>
)};

/*
{cardsData &&
        cardsData.map(
          ({
            id,
            city,
            url,
            description,
            currency,
            currentPrice,
            ratings,
            stars,
            discount,
            cashback,
            lastPrice,
            about,
            showMore,
            highlight
          }) => (
            <ExperienceCard
              key={id}
              city={city}
              about={about}
              url={url}
              description={description}
              currency={currency}
              price={currentPrice}
              ratings={ratings}
              stars={stars}
              discount={discount}
              cashback={cashback}
              lastPrice={lastPrice}
              showMore={showMore}
              highlight={highlight}
            />
          )
        )}
*/
// New York Caraousel Images

const backgroundImagesData = [
  {
    id: 1,
    url:
      'https://cdn-imgix-open.headout.com/flaps/city-specific/new-york/desktop/Broadway-week-desktop-4.png?auto=compress&fm=webp&h=501&crop=faces&fit=min'
  },
  {
    id: 2,
    url:
      'https://cdn-imgix-open.headout.com/desktop-flaps/new-york.jpg?auto=compress&fm=webp&h=501&crop=faces&fit=min'
  },
  {
    id: 3,
    url:
      'https://cdn-imgix-open.headout.com/desktop-flaps/cashback-01.jpg?auto=compress&fm=webp&h=501&crop=faces&fit=min'
  },
  {
    id: 4,
    url:
      'https://cdn-imgix-open.headout.com/desktop-flaps/ny-508-01.jpg?auto=compress&fm=webp&h=501&crop=faces&fit=min'
  },
  {
    id: 5,
    url:
      'https://cdn-imgix-open.headout.com/desktop-flaps/ny-attractions-01.jpg?auto=compress&fm=webp&h=501&crop=faces&fit=min'
  },
  {
    id: 6,
    url:
      'https://cdn-imgix-open.headout.com/flaps/non-city-specific/desktop/experience-desktop.png?auto=compress&fm=webp&h=501&crop=faces&fit=min'
  }
];

// New York Navigation Data

const NewYorkNavigationData = [
                                {
                                        id: 0,
                                        name: 'Suchen'
                                      },
                                {
                                        id: 1,
                                        name: 'Veranstaltungen'
                                      },
                                      {
                                        id: 2,
                                        name: 'Referenten'
                                      },
                                      {
                                        id: 3,
                                        name: 'Hersteller'
                                      },
                              ];

// All New York City Sections Data ~ nested ( Dynamically Rendering )

const AllNewYorkCityData = [
  {
    id: 1,
    headline: 'Kongresse / Fortbildungen im Fokus',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl eget ultricies aliquam, magna diam aliquet nunc, vitae luctus nisl nunc eget nunc. Nulla facilisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl eget ultricies aliquam, magna diam aliquet nunc, vitae luctus nisl nunc eget nunc. Nulla facilisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl eget ultricies aliquam, magna diam aliquet nunc, vitae luctus nisl nunc eget nunc. Nulla facilisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl eget ultricies aliquam, magna diam aliquet nunc, vitae luctus nisl nunc eget nunc. Nulla facilisi. ',

  },
  {
    id: 2,
    headline: 'Kongresse / Veranstaltungen',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl eget ultricies aliquam, magna diam aliquet nunc, vitae luctus nisl nunc eget nunc. Nulla facilisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl eget ultricies aliquam, magna diam aliquet nunc, vitae luctus nisl nunc eget nunc. Nulla facilisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl eget ultricies aliquam, magna diam aliquet nunc, vitae luctus nisl nunc eget nunc. Nulla facilisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl eget ultricies aliquam, magna diam aliquet nunc, vitae luctus nisl nunc eget nunc. Nulla facilisi. ',
  },
];

// New York Experiences Data


// New York Collections Data

// Collection Data


export const withUseFormHook = (Component) => {
    return props => {
        const [form] = Form.useForm();
        console.debug(form,"form");
        return <Component {...props} form={form} />
    }
}

export default withRouter(withUseFormHook(Veranstaltungen));