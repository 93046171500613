import React, { Component } from 'react';

import { Button, Row, Col, Rate, Form, Input, Select, TreeSelect, Checkbox, DatePicker, Modal } from 'antd';
import {  ConfigProvider } from 'antd';
import localObject from 'antd/es/date-picker/locale/de_DE';
import locale from 'antd/es/locale/de_DE'
import ReactDOM from 'react-dom';
import { Link, withRouter } from 'react-router-dom';
import { actions, Table, Pagination }  from 'react-data-components';
import 'react-data-components/css/table-twbs.css';
import moment from 'moment';
import 'moment/locale/de';
import dayjs from 'dayjs';
import Autocomplete from './Autocomplete';
import DataTable from './Table/CustomDataTable';
import Header from './Header/index';
import TopPicks from './Picks';
import Collections from './Collections';
import Media from './Media';
import Footer from './footer';
import ExperienceCard from './ExperienceCard';
import './Styles/new-york.css';
import { CollectionCard } from './Collections';
import { VeranstaltungenSlider } from './TopVeranstaltungen';
import { API_BASE } from "../constant";
import { calculateDistance } from "../helpers";
import { Left, Right } from './Arrows';



const { RangePicker } = DatePicker;
const { Option } = Select;
moment.locale('de');
const info = () => {
  Modal.info({
    title: 'Willkommen bei der Veranstaltungssuche!',
    content: (
      <div style={{ marginBottom: -16 }}>
        Benutzen Sie die Filterfunktionen, um schnellstmöglich zu einem passenden Treffer zu kommen.
      </div>
    ),
    onOk() { localStorage.setItem("pp_filterAkkn", true); },
  });
};

function checkOverlap(dateRange1, dateRange2) {
    const start1 = dayjs(dateRange1[0]);
    const end1 = dayjs(dateRange1[1]);
    const start2 = dayjs(dateRange2[0]);
    const end2 = dayjs(dateRange2[1]);

    return start1.isBefore(end2) && end1.isAfter(start2);
}


const columns = [
  { title: '', prop: 'Bild', render: (Bild) => <div className="city-card-img city-card-img--table" style={{ backgroundImage: `url(${API_BASE + ((((Bild || {}).data || {}).attributes || {}).url || "/uploads/pmp_ac1da8c4a1.png" )})` }}></div> },
  { title: 'Veranstaltung', prop: 'Veranstaltungsname', render : (label) => <span className="label">{label}</span> },
  { title: 'Veranstalter', prop: 'Veranstaltername', render : (label) => <span className="label">{label}</span> },
  { title: 'Datum (von - bis)', prop: 'dateComp', sortable: true, render: (dateRange) => {

  if (dateRange.length === 0) return <span>N/V</span>;

  const formattedDateRange = dateRange.map(date => moment(date).format("DD.MM.YY HH:mm"));
//    ((foundEntity || {}).date || []).map(date => moment(date).format("DD.MM.YY HH:mm"));

//  const dateRange =  <span>{formattedDateRange[0]} <i className="fas fa-arrow-right right-arrow dateArrow" /> {formattedDateRange[1]}</span>
//
//
//console.debug('dateRange',dateRange);
//   const formattedDateRange = (dateRange || []).map(date => moment(date).format("DD.MM.YY HH:mm"));
    return <span>{formattedDateRange[0]} <i className="fas fa-arrow-right right-arrow dateArrow" /> {formattedDateRange[1]}</span> }},
];

const columnsExtended = [ ...columns,
  { title: 'Ort', prop: 'Stadt', render: (ICW) => <span>{ICW || "N/V"}</span> },
];


class SelectableTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: '',
    };
  }

  selectRow = (row) => {
    this.setState({ selected: row.id });
  };

  deSelectRow = (row) => {
    this.setState({ selected: '' });
  };

  buildRowOptions = (row) => {
    return {
        onClick: () => {
            this.props.history.push(`/veranstaltungen/${row.id}`);
        },
//      onMouseOver: () => this.selectRow(row),
//      onMouseOut: () => this.deSelectRow(row),
      className: this.state.selected === row.id ? 'active-row' : null,
    };
  };

  render() {
    return (
      <DataTable buildRowOptions={this.buildRowOptions} {...this.props} />
    );
  }
}

class Veranstaltungen extends Component {
  constructor() {
    super()
    this.state = {
      lat: 51.505,
      lng: -0.09,
      zoom: 13,
      selected: '',
      filterObj: {},
      vOrt: false,
      advancedFilterShown: false,
      filteredVeranstaltungenData: []
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);


    let typingTimer;

        setTimeout(() => {
        const inputField = document.getElementById("veranstaltungsort");
        if (!inputField) return;

        if (localStorage.getItem("pp_filterAkkn") != "true") info();

        inputField.addEventListener('input', () => {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(() => {
                // Perform your action here, e.g., make an API call
                console.log("User stopped typing, performing action...");

                const veranstaltungsort = document.getElementById("veranstaltungsort").value;

                if (!veranstaltungsort) { this.setState({vOrt: false}); return; }

                fetch(`https://geocode.maps.co/search?q=${veranstaltungsort},Germany&api_key=65d4b23015fec727948775azl6467b9`)
                        .then((response) => response.json())
                        .then((data) => {
                        // update the marker based on the response
                        if (((data || {})[0] || {}).lat) {
                            this.setState({ vOrt : [data[0].lat, data[0].lon] });
                            this.filterByObj();
                        } else {
                            this.setState({ vOrt : false });
                            this.filterByObj();
                        }
                        });

            }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)
        });

        }, 4000)


    if (localStorage.getItem("pp_filterAkkn") != "true") info();
    this.setState({ filteredVeranstaltungenData: this.props.veranstaltungenData.filter( v =>  v.type === "Veranstaltung" && v.Published === true) });
  }

  componentDidUpdate(prevProps, prevState) {
  // check filterAdvancedHighlighted
    if (prevProps.filterAdvancedHighlighted !== this.props.filterAdvancedHighlighted && !this.props.filterAdvancedHighlighted) {
//        return this.setState({ filteredVeranstaltungenData: this.props.veranstaltungenData.filter( v =>  v.type === "Referent") });
    }

    if ((prevProps.veranstaltungenData || []).length !== (this.props.veranstaltungenData || []).length) {
        this.setState({ filteredVeranstaltungenData: this.props.veranstaltungenData.filter( v =>  v.type === "Veranstaltung" && v.Published === true) });
    }
    if (prevState.filterObj !== this.state.filterObj || (JSON.stringify(prevState.filterObj || {}) !== JSON.stringify(this.state.filterObj || {}))){
        this.filterByObj();
    }

  }

  selectRow = (row) => {
    this.setState({ selected: row.id });
  }

  handleRowClick = (rowData) => {
    // Perform actions based on the clicked row data
  };

    updateFilterByObj = (e, v) => {
        this.setState({ filterObj: v });
    }

  filterByObj = () => {
    const { veranstaltungenData, filterAdvancedHighlighted } = this.props;
    const { vOrt } = this.state;

    if(!filterAdvancedHighlighted) return;

    const filterObj = this.state.filterObj;
    const filterKeysWithValues = Object.keys(filterObj).filter(key => filterObj[key] !== undefined && filterObj[key] !== null && filterObj[key] !== "" && (filterObj[key] || []).length !== 0)
    const filteredReferentenData = this.props.veranstaltungenData.filter( v =>  v.type === "Veranstaltung")
    .filter( v => filterKeysWithValues.every( key => {
        if (vOrt && key === "Stadt" && v["Veranstaltungsort"]) {
            const distance = +(document.getElementById("veranstaltungsortRadius").value || 100) * 1000;
            return calculateDistance(vOrt, v["Veranstaltungsort"]) < distance;
        }

        if (key === "radius") return true;

        if (Array.isArray(filterObj[key]) ) {
            if (key === "Veranstaltungsdatum" && v["Startzeit"] && v["Endzeit"]) {
                return checkOverlap([v["Startzeit"], v["Endzeit"]], filterObj[key]);
            } else if (Array.isArray(v[key])) {
                return filterObj[key].some(vk => v[key].includes(vk))
            }
        }

        if (key === "Teilnehmergebuehr" || key === "ErwarteteTeilnehmerzahl") {
            let maxV = +((filterObj[key] || "").match(/(\d+)$/) || [])[0] || 100000;
            let filterValueV = +((v[key] || "").match(/(\d+)$/) || [])[0];
                if (v[key] && v[key].indexOf("ber") > -1) {
                    filterValueV = filterValueV + 1;
                }
                if (v[key] && filterObj[key].indexOf("ber") > -1) {
                                    return maxV <= filterValueV;
                                }
            return maxV >= filterValueV;
        }

        if (typeof filterObj[key] === 'string' && typeof v[key] === 'string') {
            return v[key].toUpperCase().indexOf(filterObj[key].toUpperCase()) > -1;
        }

        if (key === "Akkreditierunspunkte") {
           const vergabestelle = (v.Fortbildungspunkte || []).find(d => d.Vergabestelle === filterObj["Vergabestelle"]);
           let maxPoints = 0;
           if (!vergabestelle) {
                maxPoints = Math.max(0, ...(v.Fortbildungspunkte || []).map(d => d.Punkte)) || 0;
           } else {
                if (vergabestelle.Punkte) {
                    maxPoints = vergabestelle.Punkte;
                }
           }
            return maxPoints >= filterObj[key];
        }

        if (key === "Vergabestelle") {
            const vergabestelle = (v.Fortbildungspunkte || []).find(d => d.Vergabestelle === filterObj[key]);
            console.debug("comparing ", vergabestelle, filterObj[key]);
            return vergabestelle;
        }

        return v[key] === filterObj[key];
    }));

    // wait for Veranstaltungsort to stop typing


    this.setState({ filteredVeranstaltungenData: filteredReferentenData });

  };

  render() {

  const { veranstaltungenData, filterAdvancedHighlighted, form } = this.props;
  const { advancedFilterShown, filteredVeranstaltungenData } = this.state;
    return (
      <>



<div className={"search-bar-div search-bar-div--filter " + (filterAdvancedHighlighted ? "search-bar-div--active" : "search-bar-div--inactive")}> <div style={{ padding: '2em', width: '80%', height: 'auto' }} className="select-city-large ">
 <Form
 form={form}
       name="myForm"
       initialValues={{
       }}
       onValuesChange={(e,v) => this.updateFilterByObj(e,v) }
     >
       <Row gutter={24}>
         <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
           <Form.Item label="Zeitraum" name="Veranstaltungsdatum">
             <ConfigProvider locale={locale}>
               <RangePicker
                  locale={localObject}
                  format="YYYY-MM-DD"
                />
             </ConfigProvider>
           </Form.Item>
         </Col>
         <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
           <Form.Item label="Veranstaltungsort" name="Stadt">
             <Input id="veranstaltungsort" />
           </Form.Item>
         </Col>
         <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
           <Form.Item label="Maximalentfernung von Veranstaltungsort (in km)" name="radius">
             <Input defaultValue="100" id="veranstaltungsortRadius" />
           </Form.Item>
         </Col>
         <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
           <Form.Item label="Teilnehmergebühr" name="Teilnehmergebuehr">
             <Select>
              <Option value="z0-50">unter 50 €</Option>
              <Option value="z51-100">unter 100 €</Option>
              <Option value="z101-150">unter 150 €</Option>
              <Option value="züber 150">über 150 €</Option>
             </Select>
           </Form.Item>
         </Col>
         {/*<Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={8}>
           <Form.Item label="Veranstaltername" name="Veranstaltername">
             <Input id="Veranstaltername" />
           </Form.Item>
         </Col>*/}
       </Row>
       <Row gutter={24}>
         <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
           <Form.Item label=" Fortbildungspunkte für " name="Vergabestelle">
             <Select>
              <Option value="Initiative Chronische Wunde e.V. (ICW)">ICW e.V.</Option>
              <Option value="Deutsche Gesellschaft für Wundheilung und Wundbehandlung e.V. (DGfW)">DGfW e.V.</Option>
              <Option value="Akademie ZWM GmbH (ZWM)">Akademie ZWM GmbH (ZWM)</Option>
              <Option value="Registrierung beruflich Pflegender GmbH (regbp)">Registrierung beruflich Pflegender GmbH (regbp)</Option>
              <Option value="Ärztekammer">Ärztekammer</Option>
              <Option value="Fachgesellschaft Stoma-Kontinenz-Wunde e.V. (FgSKW)">Fachgesellschaft Stoma-Kontinenz-Wunde e.V. (FgSKW)</Option>
              <Option value="Verband Deutscher Podologen e.V. (VDP)">Verband Deutscher Podologen e.V. (VDP)</Option>
              <Option value="Deutscher Verband für Podologie e.V. (ZFD)">Deutscher Verband für Podologie e.V. (ZFD)</Option>
              <Option value="Verband der Diabetes-Beratungs- und Schulungsberufe in Deutschland (VDBD)">Verband der Diabetes-Beratungs- und Schulungsberufe in Deutschland (VDBD)</Option>
             </Select>
           </Form.Item>
         </Col>
         <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
           <Form.Item label="Fortbildungspunkte (mind)" name="Akkreditierunspunkte">
             <Input min={0} max={10} id="Akkreditierung" name="Akkreditierunspunkte (mind)" type="number" placeholder={0} />
           </Form.Item>
         </Col>
         {/*<Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={8}>
           <Form.Item label="Themengebiete" name="Themengebiete">
             <Select placeholder="Themengebiete" mode="multiple" options={
                           [
                               {
                                   "value": "ICW UND KURSKONZEPT SOWIE EINFÜHRUNG UND KENNENLERNEN (Ärztlicher Wundexperte ICW ®)",
                                   "label": "ICW UND KURSKONZEPT SOWIE EINFÜHRUNG UND KENNENLERNEN (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "PHYSIOLOGIE UND PATHOPHYSIOLOGIE DER WUNDHEILUNG (Ärztlicher Wundexperte ICW ®)",
                                   "label": "PHYSIOLOGIE UND PATHOPHYSIOLOGIE DER WUNDHEILUNG (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "WUNDBEURTEILUNG UND -DOKUMENTATION (Ärztlicher Wundexperte ICW ®)",
                                   "label": "WUNDBEURTEILUNG UND -DOKUMENTATION (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "WUNDTHERAPEUTIKA – Systematik (Ärztlicher Wundexperte ICW ®)",
                                   "label": "WUNDTHERAPEUTIKA – Systematik (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "LOKALTHERAPIE CHRONISCHER WUNDEN - SYSTEMATIK (Ärztlicher Wundexperte ICW ®)",
                                   "label": "LOKALTHERAPIE CHRONISCHER WUNDEN - SYSTEMATIK (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "EXSUDAT- UND GERUCHSMANAGEMENT SOWIE WUNDRANDSCHUTZ (Ärztlicher Wundexperte ICW ®)",
                                   "label": "EXSUDAT- UND GERUCHSMANAGEMENT SOWIE WUNDRANDSCHUTZ (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "DÉBRIDEMENT – SYSTEMATIK (Ärztlicher Wundexperte ICW ®)",
                                   "label": "DÉBRIDEMENT – SYSTEMATIK (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "PHYSIKALISCHE VERFAHREN IN DER WUNDBEHANDLUNG - SYSTEMATIK (Ärztlicher Wundexperte ICW ®)",
                                   "label": "PHYSIKALISCHE VERFAHREN IN DER WUNDBEHANDLUNG - SYSTEMATIK (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "SPEZIELLE WUNDTHERAPEUTIKA (Ärztlicher Wundexperte ICW ®)",
                                   "label": "SPEZIELLE WUNDTHERAPEUTIKA (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "EXTERNA INKLUSIVE HAUTPFLEGE (Ärztlicher Wundexperte ICW ®)",
                                   "label": "EXTERNA INKLUSIVE HAUTPFLEGE (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "PAVK KRANKHEITSBILD UND DIAGNOSTIK ® ABI-MESSUNG (Ärztlicher Wundexperte ICW ®)",
                                   "label": "PAVK KRANKHEITSBILD UND DIAGNOSTIK ® ABI-MESSUNG (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "PAVK THERAPIE (Ärztlicher Wundexperte ICW ®)",
                                   "label": "PAVK THERAPIE (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "CVI KRANKHEITSBILD UND DIAGNOSTIK (Ärztlicher Wundexperte ICW ®)",
                                   "label": "CVI KRANKHEITSBILD UND DIAGNOSTIK (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "CVI THERAPIE (Ärztlicher Wundexperte ICW ®)",
                                   "label": "CVI THERAPIE (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "KOMPRESSION - SYSTEMATIK UND (Ärztlicher Wundexperte ICW ®)",
                                   "label": "KOMPRESSION - SYSTEMATIK UND (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "ÖDEME UND DEREN DIFFERENZIALDIAGNOSE (Ärztlicher Wundexperte ICW ®)",
                                   "label": "ÖDEME UND DEREN DIFFERENZIALDIAGNOSE (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "LYMPHÖDEM PRIMÄR UND SEKUNDÄR - DIAGNOSTIK UND THERAPIEANSÄTZE (Ärztlicher Wundexperte ICW ®)",
                                   "label": "LYMPHÖDEM PRIMÄR UND SEKUNDÄR - DIAGNOSTIK UND THERAPIEANSÄTZE (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "HYGIENE BEI CHRONISCHEN WUNDEN (Ärztlicher Wundexperte ICW ®)",
                                   "label": "HYGIENE BEI CHRONISCHEN WUNDEN (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "INFEKTIONSBEKÄMPFUNG – (Ärztlicher Wundexperte ICW ®)",
                                   "label": "INFEKTIONSBEKÄMPFUNG – (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "DIABETISCHES FUßSYNDROM GRUNDLAGEN UND DIAGNOSTIK (Ärztlicher Wundexperte ICW ®)",
                                   "label": "DIABETISCHES FUßSYNDROM GRUNDLAGEN UND DIAGNOSTIK (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "DIABETISCHES FUßSYNDROM - THERAPIE INKLUSIVE ENTLASTUNGSVERFAHREN (Ärztlicher Wundexperte ICW ®)",
                                   "label": "DIABETISCHES FUßSYNDROM - THERAPIE INKLUSIVE ENTLASTUNGSVERFAHREN (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "ÜBERLEITUNGSMANAGEMENT UND INTERPROFESSIONELLES NETZWERK HEIL- UND HILFSMITTEL BEI PATIENTEN MIT CHRONISCHEN WUNDEN (Ärztlicher Wundexperte ICW ®)",
                                   "label": "ÜBERLEITUNGSMANAGEMENT UND INTERPROFESSIONELLES NETZWERK HEIL- UND HILFSMITTEL BEI PATIENTEN MIT CHRONISCHEN WUNDEN (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "ÜBUNGSSEQUENZ FALLMANAGEMENT (Ärztlicher Wundexperte ICW ®)",
                                   "label": "ÜBUNGSSEQUENZ FALLMANAGEMENT (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "DEKUBITUS (Ärztlicher Wundexperte ICW ®)",
                                   "label": "DEKUBITUS (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "PLASTISCH-CHIRURGISCHE THERAPIEOPTIONEN (Ärztlicher Wundexperte ICW ®)",
                                   "label": "PLASTISCH-CHIRURGISCHE THERAPIEOPTIONEN (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "PALLIATIVE WUNDBEHANDLUNG (Ärztlicher Wundexperte ICW ®)",
                                   "label": "PALLIATIVE WUNDBEHANDLUNG (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "SCHMERZTHERAPIE (Ärztlicher Wundexperte ICW ®)",
                                   "label": "SCHMERZTHERAPIE (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "GESUNDHEITSÖKONOMIE (Ärztlicher Wundexperte ICW ®)",
                                   "label": "GESUNDHEITSÖKONOMIE (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "MEDIZINPRODUKTERECHT (Ärztlicher Wundexperte ICW ®)",
                                   "label": "MEDIZINPRODUKTERECHT (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "ERNÄHRUNG BEI PATIENTEN MIT CHRONISCHEN WUNDEN (Ärztlicher Wundexperte ICW ®)",
                                   "label": "ERNÄHRUNG BEI PATIENTEN MIT CHRONISCHEN WUNDEN (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "SELTENE URSACHEN CHRONISCHER WUNDEN (Ärztlicher Wundexperte ICW ®)",
                                   "label": "SELTENE URSACHEN CHRONISCHER WUNDEN (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "THERMISCHE URSACHEN FÜR WUNDEN (Ärztlicher Wundexperte ICW ®)",
                                   "label": "THERMISCHE URSACHEN FÜR WUNDEN (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "ÜBUNGSSEQUENZ FALLMANAGEMENT (Ärztlicher Wundexperte ICW ®)",
                                   "label": "ÜBUNGSSEQUENZ FALLMANAGEMENT (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "PRÜFUNGSVORBEREITUNG (Ärztlicher Wundexperte ICW ®)",
                                   "label": "PRÜFUNGSVORBEREITUNG (Ärztlicher Wundexperte ICW ®)"
                               },
                               {
                                   "value": "SEMINAREINFÜHRUNG (Pflegetherapeut Wunde ICW ®)",
                                   "label": "SEMINAREINFÜHRUNG (Pflegetherapeut Wunde ICW ®)"
                               },
                               {
                                   "value": "WISSENSCHAFTLICH ORIENTIERTES ARBEITEN (THEMENBLOCK 1) (Pflegetherapeut Wunde ICW ®)",
                                   "label": "WISSENSCHAFTLICH ORIENTIERTES ARBEITEN (THEMENBLOCK 1) (Pflegetherapeut Wunde ICW ®)"
                               },
                               {
                                   "value": "EXPERTENSTANDARD (THEMENBLOCK 2) (Pflegetherapeut Wunde ICW ®)",
                                   "label": "EXPERTENSTANDARD (THEMENBLOCK 2) (Pflegetherapeut Wunde ICW ®)"
                               },
                               {
                                   "value": "LEBENSQUALITÄT, SELBSTPFLEGEMANAGEMENT UND EDUKATION (THEMENBLOCK 3) (Pflegetherapeut Wunde ICW ®)",
                                   "label": "LEBENSQUALITÄT, SELBSTPFLEGEMANAGEMENT UND EDUKATION (THEMENBLOCK 3) (Pflegetherapeut Wunde ICW ®)"
                               },
                               {
                                   "value": "CASEMANAGEMENT (THEMENBLOCK 4) (Pflegetherapeut Wunde ICW ®)",
                                   "label": "CASEMANAGEMENT (THEMENBLOCK 4) (Pflegetherapeut Wunde ICW ®)"
                               },
                               {
                                   "value": "PROJEKTMANAGEMENT (THEMENBLOCK 5) (Pflegetherapeut Wunde ICW ®)",
                                   "label": "PROJEKTMANAGEMENT (THEMENBLOCK 5) (Pflegetherapeut Wunde ICW ®)"
                               },
                               {
                                   "value": "GRUNDLAGEN DES SACHVERSTÄNDIGEN-GUTACHTENS (THEMENBLOCK 6) (Pflegetherapeut Wunde ICW ®)",
                                   "label": "GRUNDLAGEN DES SACHVERSTÄNDIGEN-GUTACHTENS (THEMENBLOCK 6) (Pflegetherapeut Wunde ICW ®)"
                               },
                               {
                                   "value": "PÄDAGOGISCHE AUFGABEN (THEMENBLOCK 7) (Pflegetherapeut Wunde ICW ®)",
                                   "label": "PÄDAGOGISCHE AUFGABEN (THEMENBLOCK 7) (Pflegetherapeut Wunde ICW ®)"
                               },
                               {
                                   "value": "LERNZIELÜBERPRÜFUNG (THEMENBLOCK 8) (Pflegetherapeut Wunde ICW ®)",
                                   "label": "LERNZIELÜBERPRÜFUNG (THEMENBLOCK 8) (Pflegetherapeut Wunde ICW ®)"
                               },
                               {
                                   "value": "ICW und Kurskonzept (Wundexperte ICW ®)",
                                   "label": "ICW und Kurskonzept (Wundexperte ICW ®)"
                               },
                               {
                                   "value": "Haut/Hautpflege (Wundexperte ICW ®)",
                                   "label": "Haut/Hautpflege (Wundexperte ICW ®)"
                               },
                               {
                                   "value": "Wundarten und Wundheilung (Wundexperte ICW ®)",
                                   "label": "Wundarten und Wundheilung (Wundexperte ICW ®)"
                               },
                               {
                                   "value": "Schmerz (Wundexperte ICW ®)",
                                   "label": "Schmerz (Wundexperte ICW ®)"
                               },
                               {
                                   "value": "Hospitation und Leistungsnachweise (Wundexperte ICW ®)",
                                   "label": "Hospitation und Leistungsnachweise (Wundexperte ICW ®)"
                               },
                               {
                                   "value": "Hygiene in der Wundversorgung (Wundexperte ICW ®)",
                                   "label": "Hygiene in der Wundversorgung (Wundexperte ICW ®)"
                               },
                               {
                                   "value": "Wundbeurteilung und Wunddokumentation (Wundexperte ICW ®)",
                                   "label": "Wundbeurteilung und Wunddokumentation (Wundexperte ICW ®)"
                               },
                               {
                                   "value": "Expertenstandard Pflege von Menschen mit Chronischen Wunden (DNQP) (Wundexperte ICW ®)",
                                   "label": "Expertenstandard Pflege von Menschen mit Chronischen Wunden (DNQP) (Wundexperte ICW ®)"
                               },
                               {
                                   "value": "Ulcus cruris Prophylaxe/Behandlung (Wundexperte ICW ®)",
                                   "label": "Ulcus cruris Prophylaxe/Behandlung (Wundexperte ICW ®)"
                               },
                               {
                                   "value": "Dekubitus/Prophylaxe und Therapie (Wundexperte ICW ®)",
                                   "label": "Dekubitus/Prophylaxe und Therapie (Wundexperte ICW ®)"
                               },
                               {
                                   "value": "Diabetisches Fußsyndrom (DFS)/Prophylaxe und Therapie (Wundexperte ICW ®)",
                                   "label": "Diabetisches Fußsyndrom (DFS)/Prophylaxe und Therapie (Wundexperte ICW ®)"
                               },
                               {
                                   "value": "Grundsätze Wundversorgung und Wundauflagen (Wundexperte ICW ®)",
                                   "label": "Grundsätze Wundversorgung und Wundauflagen (Wundexperte ICW ®)"
                               },
                               {
                                   "value": "Wundreinigung/Wundspülung (Wundexperte ICW ®)",
                                   "label": "Wundreinigung/Wundspülung (Wundexperte ICW ®)"
                               },
                               {
                                   "value": "Infektmanagement (Wundexperte ICW ®)",
                                   "label": "Infektmanagement (Wundexperte ICW ®)"
                               },
                               {
                                   "value": "Fallmanagement (Wundexperte ICW ®)",
                                   "label": "Fallmanagement (Wundexperte ICW ®)"
                               },
                               {
                                   "value": "Finanzierung (Wundexperte ICW ®)",
                                   "label": "Finanzierung (Wundexperte ICW ®)"
                               },
                               {
                                   "value": "Rechtliche Aspekte der Wundversorgung Edukation (Wundexperte ICW ®)",
                                   "label": "Rechtliche Aspekte der Wundversorgung Edukation (Wundexperte ICW ®)"
                               },
                               {
                                   "value": "Ernährung (Wundexperte ICW ®)",
                                   "label": "Ernährung (Wundexperte ICW ®)"
                               },
                               {
                                   "value": "Vertiefungsstunde (Wundexperte ICW ®)",
                                   "label": "Vertiefungsstunde (Wundexperte ICW ®)"
                               }
                           ]} defaultValue={[]} />
           </Form.Item>
         </Col>*/}
         </Row>


  { Object.keys(this.state.filterObj).length !== 0 && <Row gutter = {24}>
  <Col>
  <Button ghost
                   type="danger"
                   size="large"
                   onClick={() => { this.setState({ filterObj: {}});
                     form.resetFields();
                     }}
                 >
                   Filter zurücksetzen
                 </Button>

  </Col></Row> }
     </Form>

 </div></div>
  {filteredVeranstaltungenData.length > 0 && <CitySection
      key={1}
      headline={'Kongresse / Fortbildungen im Fokus'}
      description={
          <div className="top-veranstaltungen-carousel-wrap">
            <VeranstaltungenSlider veranstaltungenData={filteredVeranstaltungenData.filter( v =>  v.type === "Veranstaltung" && v.Fokusiere === true && v.Published === true ).map( v => { return { ...v, dateComp: [v.Startzeit, v.Endzeit] } })} />
          </div>
      }
    /> }

  <CitySection
      key={2}
      headline={'Kongresse / Veranstaltungen'}
      description={
      <SelectableTable
          locale="de"
          keys="name"
          history={this.props.history}
          columns={columnsExtended}
          initialData={filteredVeranstaltungenData.filter(v => v.Published === true).map( v => { return { ...v, dateComp: [v.Startzeit, v.Endzeit] } })}
          initialPageLength={5}
          initialSortBy={{ prop: 'date', order: 'descending' }}
          pageLengthOptions={[ 5, 20, 50 ]}
        />
     }
    />


      </>
    );
  }
}
//        <Media />
//        <Collections collectionsData={collectionsData} />
const CitySection = ({ headline, description, cardsData }) => (
  <div className="city-section-wrapper">
    <h2
      style={{
        textAlign: 'left', marginBottom: 8
      }}
    >
      {headline}
    </h2>
    <hr
      style={{
        backgroundColor: '#355fb3',
        width: '75px',
        height: '2px',
        border: 'none',
        marginTop: '0px',
        marginLeft: '0px',
        marginBottom: '10px'
      }}
    />
    <p
      style={{
        color: '#545454',
        fontSize: '15.3px',
        marginTop: '0px',
        textAlign: 'left',
        lineHeight: '20px'
      }}
    >
      {description}
    </p>
    <div className="travel-card-wrapper">

    </div>
  </div>
);

/*
{cardsData &&
        cardsData.map(
          ({
            id,
            city,
            url,
            description,
            currency,
            currentPrice,
            ratings,
            stars,
            discount,
            cashback,
            lastPrice,
            about,
            showMore,
            highlight
          }) => (
            <ExperienceCard
              key={id}
              city={city}
              about={about}
              url={url}
              description={description}
              currency={currency}
              price={currentPrice}
              ratings={ratings}
              stars={stars}
              discount={discount}
              cashback={cashback}
              lastPrice={lastPrice}
              showMore={showMore}
              highlight={highlight}
            />
          )
        )}
*/
// New York Caraousel Images

const backgroundImagesData = [
  {
    id: 1,
    url:
      'https://cdn-imgix-open.headout.com/flaps/city-specific/new-york/desktop/Broadway-week-desktop-4.png?auto=compress&fm=webp&h=501&crop=faces&fit=min'
  },
  {
    id: 2,
    url:
      'https://cdn-imgix-open.headout.com/desktop-flaps/new-york.jpg?auto=compress&fm=webp&h=501&crop=faces&fit=min'
  },
  {
    id: 3,
    url:
      'https://cdn-imgix-open.headout.com/desktop-flaps/cashback-01.jpg?auto=compress&fm=webp&h=501&crop=faces&fit=min'
  },
  {
    id: 4,
    url:
      'https://cdn-imgix-open.headout.com/desktop-flaps/ny-508-01.jpg?auto=compress&fm=webp&h=501&crop=faces&fit=min'
  },
  {
    id: 5,
    url:
      'https://cdn-imgix-open.headout.com/desktop-flaps/ny-attractions-01.jpg?auto=compress&fm=webp&h=501&crop=faces&fit=min'
  },
  {
    id: 6,
    url:
      'https://cdn-imgix-open.headout.com/flaps/non-city-specific/desktop/experience-desktop.png?auto=compress&fm=webp&h=501&crop=faces&fit=min'
  }
];

// New York Navigation Data

const NewYorkNavigationData = [
    {
            id: 0,
            name: 'Suchen'
          },
    {
            id: 1,
            name: 'Veranstaltungen'
          },
          {
            id: 2,
            name: 'Referenten'
          },
          {
            id: 3,
            name: 'Hersteller'
          },
  ];

// All New York City Sections Data ~ nested ( Dynamically Rendering )

const AllNewYorkCityData = [
  {
    id: 1,
    headline: 'Kongresse / Fortbildungen im Fokus',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl eget ultricies aliquam, magna diam aliquet nunc, vitae luctus nisl nunc eget nunc. Nulla facilisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl eget ultricies aliquam, magna diam aliquet nunc, vitae luctus nisl nunc eget nunc. Nulla facilisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl eget ultricies aliquam, magna diam aliquet nunc, vitae luctus nisl nunc eget nunc. Nulla facilisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl eget ultricies aliquam, magna diam aliquet nunc, vitae luctus nisl nunc eget nunc. Nulla facilisi. ',

  },
  {
    id: 2,
    headline: 'Kongresse / Veranstaltungen',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl eget ultricies aliquam, magna diam aliquet nunc, vitae luctus nisl nunc eget nunc. Nulla facilisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl eget ultricies aliquam, magna diam aliquet nunc, vitae luctus nisl nunc eget nunc. Nulla facilisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl eget ultricies aliquam, magna diam aliquet nunc, vitae luctus nisl nunc eget nunc. Nulla facilisi. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla euismod, nisl eget ultricies aliquam, magna diam aliquet nunc, vitae luctus nisl nunc eget nunc. Nulla facilisi. ',
  },
];

// New York Experiences Data

const NewYorkData = [
  {
    id: 1,
    currentPrice: 62.5,
    currency: '$',
    stars: 4.6,
    ratings: 564,
    cashback: 10,
    about: 'BROADWAY MUSICALS',
    description: 'Aladdin',
    url:
      'https://cdn-imgix.headout.com/tour/638/TOUR-IMAGE/d8da7ef3-6be5-4ab9-a88e-66a1cf8b5126-2.jpg?auto=compress&fm=webp&w=510&h=315&crop=faces&fit=min'
  },
  {
    id: 2,
    currentPrice: 37,
    lastPrice: 39.2,
    currency: '$',
    stars: 4.9,
    ratings: 533,
    cashback: 10,
    discount: 5,
    about: 'TOP OF THE ROCK',
    description: 'Top of the Rock Observation Deck: Flexible Date Tickets',
    url:
      'https://cdn-imgix.headout.com/tour/751/TOUR-IMAGE/5a1a9e72-a128-4912-90d5-b2c78e83ee42-ny-attractions-top-of-the-rock-01.jpg?auto=compress&fm=webp&w=510&h=315&crop=faces&fit=min'
  },
  {
    id: 3,
    currentPrice: 148.8,
    lastPrice: 186,
    currency: '$',
    stars: 4.9,
    ratings: 531,
    cashback: null,
    discount: 20,
    about: 'PHOTOSHOOTS AND TOURS',
    description: 'Best of New York Tours',
    url:
      'https://cdn-imgix.headout.com/tour/17185/TOUR-IMAGE/40bd85b6-fd85-4957-8012-94abad360c5f-9468-new-york-best-of-ny-tours-01.jpg?auto=compress&fm=webp&w=510&h=315&crop=faces&fit=min'
  },
  {
    id: 4,
    currentPrice: 28,
    lastPrice: 35,
    currency: '$',
    stars: 4.8,
    ratings: 270,
    cashback: null,
    discount: 20,
    about: 'ARCHITECTURE',
    description: 'NYC Street Art Tour',
    url:
      'https://cdn-imgix.headout.com/tour/17177/TOUR-IMAGE/90d6573a-e021-4f15-b2c4-2dc4d674cb8a-9460-new-york-nyc-street-art-tour-01.jpg?auto=compress&fm=webp&w=510&h=315&crop=faces&fit=min'
  },
  {
    id: 5,
    currentPrice: 68,
    lastPrice: 85,
    currency: '$',
    stars: 4.9,
    ratings: 276,
    cashback: null,
    discount: 20,
    about: 'ARCHITECTURE',
    description: 'New York Hamilton Tour: Young, Scrappy, And Hungry',
    url:
      'https://cdn-imgix.headout.com/tour/17183/TOUR-IMAGE/04586dcc-7838-403d-82f2-5a09d02db4bb-9466-new-york-new-york-hamilton-tour--young?auto=compress&fm=webp&w=510&h=315&crop=faces&fit=min'
  },
  {
    id: 6,
    currentPrice: 49.14,
    lastPrice: 56,
    currency: '$',
    stars: 4.9,
    ratings: 18,
    cashback: null,
    discount: 12,
    about: 'CITY WALKS',
    description: 'Sex and the City Tour',
    url:
      'https://cdn-imgix.headout.com/tour/954/image/3.jpg?auto=compress&fm=webp&w=510&h=315&crop=faces&fit=min'
  },
  {
    id: 7,
    currentPrice: 57,
    lastPrice: null,
    currency: '$',
    stars: 5.0,
    ratings: 1,
    cashback: null,
    about: 'NEW YORK CITY WALKING TOURS',
    description: 'Chelsea Market Food and Culture Walking Tour',
    url:
      'https://cdn-imgix.headout.com/tour/2906/TOUR-IMAGE/4414a92f-1570-4b10-b05d-deaa5737d7f8-2005-ChelseaFoodTour-1-2-.jpg?auto=compress&fm=webp&w=510&h=315&crop=faces&fit=min'
  },
  {
    id: 8,
    currentPrice: 288,
    lastPrice: 360,
    currency: '$',
    stars: 4.7,
    ratings: 268,
    cashback: null,
    about: 'ARCHITECTURE',
    discount: 20,
    description: 'Architecture of New York Tour',
    url:
      'https://cdn-imgix.headout.com/tour/6755/TOUR-IMAGE/132dfbb2-b633-4f8d-a874-d55431e70f6f-4277-new-york-architecture-of-new-york-01.jpg?auto=compress&fm=webp&w=510&h=315&crop=faces&fit=min'
  },
  {
    id: 9,
    currentPrice: 68,
    lastPrice: 85,
    currency: '$',
    stars: 5.0,
    ratings: 279,
    cashback: null,
    discount: 20,
    about: 'CRUISES',
    description: 'New York City Sightseeing Boat Tour',
    url:
      'https://cdn-imgix.headout.com/tour/17191/TOUR-IMAGE/d30695cf-8ed4-4b51-be01-13066b0827ef-9474-new-york-new-york-city-sightseeing-boat-tour-01.jpg?auto=compress&fm=webp&w=510&h=315&crop=faces&fit=min'
  },
  {
    id: 10,
    currentPrice: 33.75,
    lastPrice: 45,
    currency: '$',
    stars: 4.5,
    ratings: 330,
    cashback: null,
    discount: 25,
    about: 'CITY WALKS',
    description: 'Sunrise Yoga Walk',
    url:
      'https://cdn-imgix.headout.com/tour/17189/TOUR-IMAGE/a8ce2e14-2a95-44a4-b150-223be1f156bb-9472-new-york-sunrise-yoga-walk-01.jpg?auto=compress&fm=webp&w=510&h=315&crop=faces&fit=min'
  },
  {
    id: 11,
    currentPrice: 24,
    lastPrice: 30,
    currency: '$',
    stars: 4.8,
    ratings: 663,
    cashback: null,
    discount: 20,
    about: 'AFTER HOURS',
    description: 'NYFW Fashion Street Show',
    url:
      'https://cdn-imgix.headout.com/tour/17180/TOUR-IMAGE/4e9e7963-5e55-46a5-9a85-53532c20e747-9463-new-york-nyfw-fashion-street-show-01.jpg?auto=compress&fm=webp&w=510&h=315&crop=faces&fit=min'
  },
  {
    id: 12,
    currentPrice: 28,
    lastPrice: 35,
    currency: '$',
    stars: 4.7,
    ratings: 554,
    cashback: null,
    discount: 20,
    about: 'AFTER HOURS',
    description: `Open Bar Henny N' Paint`,
    url:
      'https://cdn-imgix.headout.com/tour/17181/TOUR-IMAGE/0f97d532-bd32-4c71-a219-ee477daf1a53-9464-new-york-open-bar-henny-and-paint-01.jpg?auto=compress&fm=webp&w=510&h=315&crop=faces&fit=min'
  },
  {
    id: 13,
    currentPrice: 40.5,
    lastPrice: 79,
    currency: '$',
    stars: null,
    ratings: null,
    cashback: null,
    discount: 49,
    about: 'BROADWAY SHOW TICKETS',
    description: `King Kong - Broadway Week Discount`,
    url:
      'https://cdn-imgix.headout.com/tour/18201/TOUR-IMAGE/a24bde23-2e32-49d4-bf14-b933fe60fe52-c817b2f3-194d-4fde-9ad8-fccbaf50ed31-9339-new-york-king-kong-01.jpg?auto=compress&fm=webp&w=510&h=315&crop=faces&fit=min'
  },
  {
    id: 14,
    currentPrice: 29,
    lastPrice: null,
    currency: '$',
    stars: 4.6,
    ratings: 681,
    cashback: 10,
    about: 'BROADWAY MUSICALS',
    description: `The Phantom of the Opera`,
    url:
      'https://cdn-imgix.headout.com/tour/652/TOUR-IMAGE/cd0fa708-27c2-4145-9fcf-14e84d910456-517-new-york-phantom-of-the-opera-00.jpg?auto=compress&fm=webp&w=510&h=315&crop=faces&fit=min'
  },
  {
    id: 15,
    currentPrice: 69,
    lastPrice: 79,
    currency: '$',
    stars: 4.6,
    ratings: 274,
    cashback: 10,
    discount: 46,
    about: 'BROADWAY MUSICALS',
    description: `Anastasia`,
    url:
      'https://cdn-imgix.headout.com/tour/7573/TOUR-IMAGE/fa5af28b-c617-446d-a475-ced33c58c98d-4653-new-york-anastasia-01.jpg?auto=compress&fm=webp&w=510&h=315&crop=faces&fit=min'
  },
  {
    id: 16,
    currentPrice: 99,
    lastPrice: null,
    currency: '$',
    stars: 4.7,
    ratings: 347,
    cashback: 10,
    about: 'BROADWAY MUSICALS',
    description: `The Lion King`,
    url:
      'https://cdn-imgix.headout.com/tour/637/TOUR-IMAGE/0442ebf8-8ad3-4e1b-bb49-3b7dde81eb35-507-new-york-the-lion-king-01.jpg?auto=compress&fm=webp&w=510&h=315&crop=faces&fit=min'
  },
  {
    id: 17,
    currentPrice: 49.5,
    lastPrice: null,
    currency: '$',
    stars: 4.5,
    ratings: 557,
    cashback: 10,
    discount: 44,
    about: 'BROADWAY MUSICALS',
    description: `Chicago`,
    url:
      'https://cdn-imgix.headout.com/tour/654/TOUR-IMAGE/41c27ecf-f84a-47ff-9517-fe88303ac5e8-1.jpg?auto=compress&fm=webp&w=510&h=315&crop=faces&fit=min'
  },
  {
    id: 18,
    currentPrice: 49,
    lastPrice: 69,
    currency: '$',
    stars: 4.5,
    ratings: 445,
    cashback: 10,
    discount: 41,
    about: 'BROADWAY MUSICALS',
    description: `Beautiful: The Carole King Musical`,
    url:
      'https://cdn-imgix.headout.com/tour/956/TOUR-IMAGE/17818c73-4692-4e79-8b82-b44f646165f7-734-new-york-beautiful-the-carole-01.jpg?auto=compress&fm=webp&w=510&h=315&crop=faces&fit=min'
  },
  {
    id: 19,
    currentPrice: 55,
    lastPrice: 119,
    currency: '$',
    stars: 4.6,
    ratings: 444,
    cashback: 10,
    discount: 53,
    about: 'BROADWAY MUSICALS',
    description: `Kinky Boots`,
    url:
      'https://cdn-imgix.headout.com/tour/651/TOUR-IMAGE/0dce4644-0607-4de8-8a4e-fb8e64ac0cdc-9.jpg?auto=compress&fm=webp&w=510&h=315&crop=faces&fit=min'
  },
  {
    id: 20,
    currentPrice: 89,
    lastPrice: null,
    currency: '$',
    stars: 4.6,
    ratings: 561,
    cashback: 10,
    about: 'BROADWAY MUSICALS',
    description: `Wicked`,
    url:
      'https://cdn-imgix.headout.com/tour/647/TOUR-IMAGE/35c36ce6-af74-4fdb-a14f-a2f947f6c713-5.jpg?auto=compress&fm=webp&w=510&h=315&crop=faces&fit=min'
  },
  {
    id: 21,
    currentPrice: 59,
    lastPrice: 99,
    currency: '$',
    stars: 4.8,
    ratings: 55,
    cashback: null,
    discount: 40,
    about: 'BROADWAY MUSICALS',
    description: `Pretty Woman`,
    url:
      'https://cdn-imgix.headout.com/tour/16624/TOUR-IMAGE/ab386fd8-b118-423c-8c7a-c9e306235c5a-9213-new-york-pretty-woman-01.jpg?auto=compress&fm=webp&w=510&h=315&crop=faces&fit=min'
  },
  {
    id: 22,
    currentPrice: 51,
    lastPrice: 99.5,
    currency: '$',
    stars: 4.8,
    ratings: 17,
    cashback: 48,
    about: 'BROADWAY MUSICALS',
    description: `Frozen - Broadway Week Discount`,
    url:
      'https://cdn-imgix.headout.com/tour/17069/TOUR-IMAGE/2f568870-80a6-4be1-84c4-3b6efe8df5f1-214d0dcf-6b0a-4d8d-90f0-11d601678377-7909-new-york-frozen-01.jpg?auto=compress&fm=webp&w=510&h=315&crop=faces&fit=min'
  },
  {
    id: 23,
    currentPrice: 69.5,
    lastPrice: 99.5,
    currency: '$',
    stars: 4.5,
    ratings: 129,
    cashback: 43,
    about: 'BROADWAY MUSICALS',
    description: `Mean Girls`,
    url:
      'https://cdn-imgix.headout.com/tour/14470/TOUR-IMAGE/acc17ba6-70f0-48da-8bea-3b744aa489b2-7911-new-york-mean-girls-01.jpg?auto=compress&fm=webp&w=510&h=315&crop=faces&fit=min'
  }
];

// New York Collections Data

// Collection Data

const collectionsData = [
  {
    id: 1,
    description: 'Broadway Show Tickets',
    url:
      'https://cdn-imgix.headout.com/category/24/image/66000036-0523-4859-87b7-83d628b8843c-BroadwayShowTickets.jpg?auto=compress&fm=webp&w=480&h=480&crop=faces&fit=min'
  },
  {
    id: 2,
    description: 'City Walks',
    url:
      'https://cdn-imgix.headout.com/category/29/image/379e4fd4-3c83-43a4-b4e7-8422b0356867-NYCCityWalks.jpg?auto=compress&fm=webp&w=480&h=480&crop=faces&fit=min'
  },
  {
    id: 3,
    description: 'Foods and Drinks',
    url:
      'https://cdn-imgix.headout.com/category/26/image/d405cabb-965f-4c15-8828-4276755636a2-NYCFoodandDrinks.jpg?auto=compress&fm=webp&w=480&h=480&crop=faces&fit=min'
  },
  {
    id: 4,
    description: 'NYC Tours',
    url:
      'https://cdn-imgix.headout.com/category/119/image/7be3f1f2-2002-4466-b6e0-890952bc408e-NYCTours.jpg?auto=compress&fm=webp&w=480&h=480&crop=faces&fit=min'
  },
  {
    id: 5,
    description: 'Entertainment',
    url:
      'https://cdn-imgix.headout.com/category/334/image/e7b12e66-aa7e-4cfc-ac43-262c6ff87f7a-334.jpeg?auto=compress&fm=webp&w=480&h=480&crop=faces&fit=min'
  },
  {
    id: 6,
    description: 'New York City Passes',
    url:
      'https://cdn-imgix.headout.com/category/1303/image/ed686051-e9b8-493c-b0a5-dc27c79a726c-1303.jpeg?auto=compress&fm=webp&w=480&h=480&crop=faces&fit=min'
  },
  {
    id: 7,
    description: 'NYC 101',
    url:
      'https://cdn-imgix.headout.com/category/20/image/50dd86bd-0af8-4fef-a334-94a699d3a67c-NYC101.jpg?auto=compress&fm=webp&w=480&h=480&crop=faces&fit=min'
  },
  {
    id: 8,
    description: 'Cruises',
    url:
      'https://cdn-imgix.headout.com/category/21/image/f083df8f-c083-4766-b496-bd67cb1199ad-NYCCruises.jpg?auto=compress&fm=webp&w=480&h=480&crop=faces&fit=min'
  }
];


export const withUseFormHook = (Component) => {
    return props => {
        const [form] = Form.useForm();
        console.debug(form,"form");
        return <Component {...props} form={form} />
    }
}

export default withRouter(withUseFormHook(Veranstaltungen));