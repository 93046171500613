import React, { useEffect, useState, useContext, useRef } from "react";
import { Form, Input, Select, Row, Col, Divider, Switch, Button, Upload, message } from "antd";
import { UploadOutlined,InboxOutlined  } from '@ant-design/icons';
import { PicturesWall } from './PicturesWall';
import { AuthContext } from "../context/AuthContext";
import localObject from '../germanLocale.json';
import { API, API_BASE, STRAPI_API } from "../constant";
import { getToken } from "../helpers";
import { normFile } from "./PicturesWall";
import { useAuthContext } from "../context/AuthContext";

const { Option } = Select;

const VersorgungspartnerForm = ({ type, id, veranstaltungenData }) => {
const { setUser, user, availableSubs, sub } = useAuthContext();
  const selectedSubTitle = (sub ? ((availableSubs || []).find((availableSub) => (availableSub || {}).stripePlanId === ((sub || {}).plan || {}).id) || {}).title : false || "");



const veranstaltung = veranstaltungenData.find((veranstaltung) => veranstaltung.id == id) || {};
const [fileList, setFileList] = useState(((veranstaltung || {}).Medien || {}).data || []);
  const [loading, setLoading] = useState(false);
  const [hauptkategorie, setHauptkategorie] = useState({});
  const [defaultValues, setDefaultValues] = useState({});
  const [form] = Form.useForm();
  const hasSetDefaultValues = useRef(false);

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


    useEffect(() => {
        const currentValues = form.getFieldsValue();
        // Check if form values are empty
        const isFormEmpty = Object.values(currentValues).every(value => value === undefined || value === null || value === "");

        if (isFormEmpty) {
            form.setFieldsValue(defaultValues)
        }
    }, [form, defaultValues])

    useEffect(() => {
            const currentValues = form.getFieldsValue();
            // Check if form values are empty
            const isFormEmpty = Object.values(currentValues).every(value => value === undefined || value === null || value === "");

            if (!isFormEmpty) return;

        const veranstaltung = veranstaltungenData.find((veranstaltung) => veranstaltung.id == id) || {};
        if (!hasSetDefaultValues.current && Object.keys(veranstaltung).length > 0) {
            const defaultValues = {};
            Object.keys(veranstaltung).forEach((key) => {
                defaultValues[key] = veranstaltung[key];
            })

            setHauptkategorie(veranstaltung.Kategorie);


            setDefaultValues({
                ...defaultValues, // veranstaltung.date || 0// new Date(veranstaltung.date).toISOString().split('T')[0],
             });
          hasSetDefaultValues.current = true; // Mark default values as set
        }
    }, [veranstaltungenData]);

  const handleVeranstaltungUpdate = async (data) => {
    setLoading(true);
    try {
      const veranstaltung = veranstaltungenData.find((veranstaltung) => veranstaltung.id == id);
      let response;

        const dataToPost = Object.fromEntries(
           Object.entries(data).filter(([key, value]) => value !== undefined)
         );

         if (!dataToPost["Logo"] || (veranstaltung && (dataToPost["Logo"] || {}).data)) delete dataToPost["Logo"];
//         if (!dataToPost["Medien"] || (veranstaltung && (dataToPost["Medien"] || {}).data)) delete dataToPost["Medien"];
         dataToPost.Medien = (fileList || []).map( media => {
            if (((media.response) || []) [0]) {
                return media.response[0];
            } else {
                return media;
            }
         });
         if ((((dataToPost.dragger || {})[0] || {}).response || {})[0]) dataToPost["Logo"]  = dataToPost.dragger[0].response[0];


         (dataToPost["Produkt"] || []).forEach((produkt, index) => {
              if (!produkt["Bild"] || (veranstaltung && (dataToPost.Produkt[index]["Bild"] || {}).data)) delete produkt["Bild"];
              if ((((produkt || {}).dragger || {})[0] || {}).response) dataToPost["Produkt"][index]["Bild"] = produkt.dragger[0].response[0];
         });

         dataToPost.createdby = (user || {}).id;

         delete dataToPost.dragger;

      if (veranstaltung) {
        response = await fetch(`${API}/versorgungspartners/${veranstaltung.id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify({data: dataToPost}),
          });
      } else {
        response = await fetch(`${API}/versorgungspartners`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getToken()}`,
            },
            body: JSON.stringify({data: dataToPost}),
          });
      }
      const responseData = await response.json();

      if (responseData.error) {
              alert("Es konnte nicht gespeichert werden, bitte schicken Sie unserem Support die genauen Daten die gespeichert werden sollten um das Problem zu lösen oder verändern Sie die Eingabe.");
            } else {
              message.success("Daten erfolgreich gespeichert!");
              window.location.replace("/admin/meine-inserate");
            }
    } catch (error) {
      console.error(error);
      message.error("Es ist etwas schief gelaufen...");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      form={form}
      name="basic"
      onFinish={handleVeranstaltungUpdate}
      onFinishFailed={onFinishFailed}
      initialValues={defaultValues}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 18 }}
    >
      <Form.Item
        label="Name"
        name="Name"
        rules={[{ required: true, message: 'Bitte auswählen: Name!' }]}
      >
        <Input placeholder="Name" />
      </Form.Item>

      <Form.Item
        label="Stadt"
        name="Stadt"
        rules={[{ required: true, message: 'Bitte auswählen: Stadt!' }]}
      >
        <Input placeholder="Stadt" />
      </Form.Item>

      <Form.Item
        label="PLZ"
        name="PLZ"
        rules={[{ required: true, message: 'Bitte auswählen: PLZ!' }]}
      >
        <Input type="number" placeholder="PLZ" />
      </Form.Item>

      <Form.Item
        label="Bundesland"
        name="Bundesland"
        rules={[{ required: true, message: 'Bundesland bitte auswählen!' }]}
      >
        <Select placeholder="Bundesland auswählen">
          {["Baden-Württemberg", "Bayern", "Berlin", "Brandenburg", "Bremen", "Hamburg", "Hessen", "Mecklenburg-Vorpommern", "Niedersachsen", "Nordrhein-Westfalen", "Rheinland-Pfalz", "Saarland", "Sachsen", "Sachsen-Anhalt", "Schleswig-Holstein", "Thüringen"].map((state) => (
            <Option key={state} value={state}>{state}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
              label="Homepage"
              name="Homepage"
            >
              <Input placeholder="Homepage URL" />
            </Form.Item>

        {(((veranstaltung.Logo || {}).data || {}).attributes) && <Col span={12}><img className={"leafImage leafImage--veranstaltung"} src={API_BASE + veranstaltung.Logo.data.attributes.url}  /> </Col> }
                                        <Col span={(((veranstaltung.Logo || {}).data || {}).attributes) ? 20 : 24}>
                                           <Form.Item label={ (((veranstaltung.Logo || {}).data || {}).attributes) ? "Logo aktualisieren" : "Logo hochladen"} name="Logo">
                                                <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
                                                  <Upload.Dragger name="files"
                                                  crossOrigin={"use-credentials"}
                                                  data={{
                                                    ref: "api::veranstaltung.veranstaltung",
                                                    field: "Logo",
                                                  }}
                                                  action={`${API}/upload`}
                                                   headers={{ "Authorization": `Bearer ${getToken()}`}}>
                                                    <p className="ant-upload-drag-icon">
                                                      <InboxOutlined />
                                                    </p>
                                                    <p className="ant-upload-text">Klicken Sie hier oder ziehen Sie das Logo in diesen Bereich</p>
                                                    <p className="ant-upload-hint">Bilder im Format .jpeg, png. oder .jpg werden unterstützt</p>
                                                  </Upload.Dragger>
                                                </Form.Item>
                                              </Form.Item>
                                          </Col>
                        <Divider />

                      { selectedSubTitle === "Versorgungspartner: Premium" && <Form.Item label="Medien" name="Medien (Erlaubt sind sowohl PDF, als auch Bild-, Video- und Audioformate)">
                        <PicturesWall fileList={fileList} setFileList={setFileList} />
                      </Form.Item> }

      <Form.Item
        label="Kategorie"
        name="Kategorie"
        rules={[{ required: true, message: 'Bitte Kategorie auswählen!' }]}
      >
        <Select  onSelect={ (v) =>  { setHauptkategorie(v)  }} placeholder="Kategorie">
          {["Apotheke", "Ergo- und Logotherpiepraxis", "Klinik", "Rehaeinrichtung", "Facharzt", "Hausarzt/MVZ", "Pflegeheim", "Sozialdienst/Pflegedienst/SAPV", "Homecareunternehmen", "Wundzentrum", "Sanitätshaus", "Podologiepraxis", "Physiotherapiepraxis", "Hebamme", "Gesundheitskasse", "Beratungsstelle"].map((category) => (
            <Option key={category} value={category}>{category}</Option>
          ))}
        </Select>
      </Form.Item>

      { ["Klinik","Rehaeinrichtung","Facharzt"].includes(hauptkategorie) && <Form.Item
        label="Fachgebiete"
        name="Fachgebiete"
      >
        <Input.TextArea placeholder="Fachgebiete" />
      </Form.Item> }

      { ["Klinik","Rehaeinrichtung","Facharzt","Hausarzt/MVZ"].includes(hauptkategorie) && <Form.Item
        label="Barrierefreiheit"
        name="Barrierefreiheit"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item> }

      { ["Klinik","Rehaeinrichtung"].includes(hauptkategorie) && <Form.Item
        label="Ausstattung"
        name="Ausstattung"
      >
        <Input.TextArea placeholder="Ausstattung" />
      </Form.Item> }

      { ["Klinik","Rehaeinrichtung","Pflegeheim"].includes(hauptkategorie) && <Form.Item
        label="Bettenanzahl"
        name="Bettenanzahl"
      >
        <Input type="number" placeholder="Bettenanzahl" />
      </Form.Item> }

      { ["Klinik"].includes(hauptkategorie) && <Form.Item
        label="Behandelte Krankheiten"
        name="BehandelteKrankheiten"
      >
        <Input.TextArea placeholder="Welche Krankheiten werden behandelt?" />
      </Form.Item> }

      { ["Hausarzt/MVZ"].includes(hauptkategorie) && <Form.Item
        label="Behandlungsmöglichkeiten"
        name="Behandlungsmoeglichkeiten"
      >
        <Input.TextArea placeholder="Behandlungsmöglichkeiten" />
      </Form.Item> }

      { ["Pflegeheim"].includes(hauptkategorie) && <Form.Item
        label="Spezialisierung"
        name="Spezialisierung"
      >
        <Input.TextArea placeholder="Spezialisierung" />
      </Form.Item> }

      <Form.Item
        label="Spezialisierung"
        name="Aufgabengebiete"
      >
        <Select mode="multiple" placeholder="Spezialisierung auswählen">
          {["Wundversorgung", "Stomaversorgung", "Enterale Versorgung", "Parenterale Versorgung",
           "Inkontinenzversorgung", "Tracheostomaversorgung", "IV-Antibiose", "Sauerstofftherapie"].map((field) => (
            <Option key={field} value={field}>{field}</Option>
          ))}
        </Select>
      </Form.Item>

      { ["Homecareunternehmen", "Wundzentrum"].includes(hauptkategorie) && <Form.Item
        label="Spezialbehandlungen"
        name="Spezialbehandlungen"
      >
        <Select mode="multiple" placeholder="Spezialbehandlungen auswählen">
          {["Plasmatherapie", "Lasertherapie", "Lichttherapie", "Ultraschalltherapie", "Sauerstofftherapie", "Kompressionsstrümpfe", "Bandagen"].map((field) => (
            <Option key={field} value={field}>{field}</Option>
          ))}
        </Select>
      </Form.Item> }


        <Divider />

            <Col span={24}>
              <Form.Item
                label={<span  style={{fontWeight: 800}}>Profil veröffentlichen</span>}
                name="Published"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col>
            { selectedSubTitle === "Versorgungspartner: Premium" && <Col span={24}>
              <Form.Item
                label={<span  style={{fontWeight: 400}}>Fokussiere Profil</span>}
                name="Fokusiere"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
            </Col> }

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Speichern
        </Button>
      </Form.Item>
    </Form>
  );
};

export default VersorgungspartnerForm;
