import React, { useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { message } from "antd";
import { API, STRAPI_API, BEARER } from "../../constant";
import { useEffect } from "react";
import { getToken, removeToken, getExpire } from "../../helpers";

const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState();
  const [activeSubscription, setActiveSubscription] = useState();
  const [availableSubscriptions, setAvailableSubscriptions ] = useState();
  const [subPending, setSubPending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const authToken = getToken();
  const expire = getExpire();

  const fetchLoggedInUser = async (token) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/users/me`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const data = await response.json();


      SS_GetSubStatus(data);
    } catch (error) {
      console.error(error);
      message.error("Es ist etwas schief gelaufen...");
    } finally {
      setIsLoading(false);
    }
  };

  const handleUser = (user) => {
    setUserData(user);
  };

  const handleSub = (sub) => {
    setActiveSubscription(sub);
  };

  const handleUserAndPlan = (user, sub) => {
    setActiveSubscription(sub);
    setUserData(user);
  }

  const getSubsAvailable = () => {
    const retrieveCheckoutSessionUrl = `${STRAPI_API}/getProduct/0/15/name/asc`;

    try {
        fetch(retrieveCheckoutSessionUrl, {
          method: 'get',
          mode: 'cors',
          headers: new Headers({
            'Authorization': `Bearer ${getToken()}`,
            'Content-Type': 'application/json',
          }),
        }).then(response => {
            if (response.ok && response.status !== 204) {
              response.json().then(json => {
                setAvailableSubscriptions(json.res);
                console.log(json);
              });
            }
          }).catch(error => {
            console.debug("error", error);
         })
        } catch (error) {
          console.error(error);
    }
  }

  const SS_GetSubStatus = (data) => {
      const userEmail = (data.email || "").toLowerCase();
      const retrieveCheckoutSessionUrl = `${STRAPI_API}/getSubscriptionStatus/${userEmail}`;

    try {
        fetch(retrieveCheckoutSessionUrl, {
          method: 'get',
          mode: 'cors',
          headers: new Headers({
            'Authorization': `Bearer ${getToken()}`,
            'Content-Type': 'application/json',
          }),
        }).then(response => {
            if (response.ok && response.status !== 204) {
              response.json().then(json => {
                if (json.data.length > 0) {
                    handleUserAndPlan(data, json.data.sort((a, b) => b.created - a.created)[0]);
                } else {
                    handleUserAndPlan(data, false);
                }
                console.log(json);
              });
            } else {
                handleUserAndPlan(data, false);
            }
          })
        } catch (error) {
            handleUserAndPlan(data, false);
            console.error(error);
        }
  };

  useEffect(() => {
    getSubsAvailable();

    if (authToken && new Date().getTime() < new Date(getExpire()).getTime()) {
        fetchLoggedInUser(authToken);
    } else {
        removeToken();
        setUserData(null);

    }
  }, [authToken]);

  console.debug("authcontext");

  return (
    <AuthContext.Provider
      value={{ user: userData, getSubsAvailable: getSubsAvailable, sub: activeSubscription, availableSubs: availableSubscriptions, setUserAndPlan: handleUserAndPlan, setUser: handleUser, setSub: handleSub, isLoading }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
