import React from 'react';
import './Styles/footer.css';
import HeadoutLogo from './Images/pmp.png';
import Googlelogo from './Images/gplus-connect.png';
import Twitterlogo from './Images/twitter-connect.png';
import Facebooklogo from './Images/fb-connect.png';
import Pinterestlogo from './Images/pinterest-connect.png';
import Instagramlogo from './Images/instagram-connect.png';

import { Link } from 'react-router-dom';
const Footer = () => (
  <div className="footer-wrap">
    <div className="footer-wrapper">
      <div className="column-one">
        <img id="footer" src={HeadoutLogo} style={{width: "28px"}} alt="Headout" />
        <p>Vermittlungsplattform für Referenten, Veranstalter und
           Industrie</p>

        <p>&copy; PMP.online</p>
      </div>
      <div className="column-two">
        <h3>Dienstleistungen</h3>
        <ul>

          <li><Link to={{ pathname: `/veranstaltungen` }} key={'v_sa'} className="link_sa view-all footer-link">Veranstaltungen</Link></li>
          <li><Link to={{ pathname: `/referenten` }} key={'v_sa'} className="link_sa view-all footer-link">Referenten</Link></li>
          <li><Link to={{ pathname: `/hersteller` }} key={'v_sa'} className="link_sa view-all footer-link">Hersteller</Link></li>
          <li><Link to={{ pathname: `/versorgungspartner` }} key={'v_sa'} className="link_sa view-all footer-link">Versorgungspartner</Link></li>
        </ul>
      </div>
      <div className="column-three">
        <h3>Über uns</h3>
        <ul>
          <li><Link to={{ pathname: `/info` }} key={'v_sa'} className="link_sa view-all footer-link">Über PMP.online</Link></li>
          <li><Link to={{ pathname: `/datenschutz` }} key={'v_sa'} className="link_sa view-all footer-link">Datenschutz</Link></li>
          <li><Link to={{ pathname: `/impressum` }} key={'v_sa'} className="link_sa view-all footer-link">Impressum</Link></li>
        </ul>
      </div>
      <div className="column-four">
        <h3 style={{ paddingBottom: '12px' }} >Kontakt</h3>
        <p style={{ paddingLeft: '0px' }} >Kontaktieren Sie uns gerne per E-Mail:</p>
        <p style={{ paddingLeft: '0px', color: '#4fc3f7', cursor: 'pointer' }}>
          info@pmp-online.net
        </p>

      </div>
    </div>
  </div>
);

export default Footer;
