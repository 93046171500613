import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { Left, Right } from './Arrows';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { API_BASE } from '../constant';
import './Styles/top-veranstaltungen.css';


class TopVeranstaltungen extends Component {
  render() {
    const { veranstaltungenData } = this.props;
    return (
      <div>
        <div className="top-veranstaltungen-wrapper">
          <div className="headout-picks-nav">
            <div>
              <h1>Veranstaltungen im Fokus</h1>
            </div>
            <div className="view-all">
                <Link
                  to={{ pathname: `/veranstaltungen` }}
                  key={'v_sa'}
                  className="link_sa view-all"
                >
                  <h3>Alle anzeigen</h3>
                  <i className="fas fa-arrow-right" />
                </Link>
          </div>
          </div>
          <hr
            style={{
              backgroundColor: '#355fb3',
              width: '75px',
              height: '2px',
              border: 'none',
              marginTop: '0px',
              marginLeft: '0px',
              marginBottom: '20px'
            }}
          />
          <div className="top-veranstaltungen-carousel-wrap">
            <VeranstaltungenSlider veranstaltungenData={veranstaltungenData.filter(v => v.Fokusiere === true && v.Published === true)} />
          </div>
          <hr
            style={{
              height: '1px',
              color: '#e7e7e7',
              borderTop: 'none',
              borderLeft: 'none'
            }}
          />
        </div>
        <hr className="section-divide-hr" />
      </div>
    );
  }
}

export class VeranstaltungenSlider extends React.Component {


  render() {
    const { veranstaltungenData, superResource } = this.props;

    // detect available width for element id="root"


    const screenWidth = document.getElementById("root").clientWidth;

    var settings = {
      infinite: true,
      speed: 500,
      slidesToShow: screenWidth > 756 ? 5 : 1,
      slidesToScroll: screenWidth > 756 ? 5 : 1,
      nextArrow: <Right />,
      prevArrow: <Left />
    };


    return (
    <>
          {(veranstaltungenData || []).length > 0 ?
          <Slider {...settings}>
            {veranstaltungenData &&
              veranstaltungenData.map(({ id, Veranstaltungsname, Bild, Logo, Stadt, Beschreibung, Name, Kategorie }) => (
                <Link
                  to={{ pathname: (superResource === "Versorgungspartner" ? `/versorgungspartner/${id}` : `/veranstaltungen/${id}`) }}
                  key={id}
                  className="link"
                >
                  <CityCard city={Veranstaltungsname || Name} url={((((Bild || Logo) || {}).data || {}).attributes) ? (API_BASE + (Bild || Logo).data.attributes.url) : "/pmp.png"} description={Stadt ? (`in ${Stadt}`) :  Kategorie} />
                </Link>
              ))}
      </Slider> : <div>Keine { superResource ? superResource : "Veranstaltung" } gefunden, die dem Filter entsprechen...</div>}
      </>
    );
  }
}

class CityCard extends Component {
  render() {
    const url = `url(${this.props.url})`;
    return (
      <div className="city-card-wrapper">
        <div className="city-card">
          <div
            className="city-card-img"
            style={{
              backgroundImage: url
            }}
          />
          <div className="city-details">
            <div id="triangle" />
            <p>{this.props.city}</p>
            <div id="city-hidden">
              <p>{this.props.description}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TopVeranstaltungen;
