import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { API_BASE } from '../constant';
import { Left, Right } from './Arrows';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './Styles/top-veranstaltungen.css';


class TopVersorgungspartner extends Component {
  render() {
    const { veranstaltungenData } = this.props;
    return (
      <div>
        <div className="top-veranstaltungen-wrapper">
          <div className="headout-picks-nav">
            <div>
              <h1>Versorgungspartner im Fokus</h1>
            </div>
            <div className="view-all">
                <Link
                  to={{ pathname: `/versorgungspartner` }}
                  key={'v_sa'}
                  className="link_sa view-all"
                >
                  <h3>Alle anzeigen</h3>
                  <i className="fas fa-arrow-right" />
                </Link>
          </div>
          </div>
          <hr
            style={{
              backgroundColor: '#355fb3',
              width: '75px',
              height: '2px',
              border: 'none',
              marginTop: '0px',
              marginLeft: '0px',
              marginBottom: '20px'
            }}
          />
          <div className="top-veranstaltungen-carousel-wrap">
            <VersorgungspartnerSlider veranstaltungenData={veranstaltungenData.filter( v => v.Published === true)} />
          </div>
          <hr
            style={{
              height: '1px',
              color: '#e7e7e7',
              borderTop: 'none',
              borderLeft: 'none'
            }}
          />
        </div>
        <hr className="section-divide-hr" />
      </div>
    );
  }
}

class VersorgungspartnerSlider extends React.Component {


  render() {
    const { veranstaltungenData } = this.props;

    // detect available width for element id="root"


    const screenWidth = document.getElementById("root").clientWidth;

    var settings = {
      infinite: true,
      speed: 500,
      slidesToShow: screenWidth > 756 ? 5 : 1,
      slidesToScroll: screenWidth > 756 ? 5 : 1,
      nextArrow: <Right />,
      prevArrow: <Left />
    };
    return (
      <Slider {...settings}>
        {veranstaltungenData &&
          veranstaltungenData.map(({ id, label, Name, image, Logo, Bild, Beschreibung, Kategorie, description }) => (
            <Link
              to={{ pathname: `/versorgungspartner/${id}` }}
              key={id}
              className="link"
            >
              <CityCard city={Name} url={((((Bild || Logo) || {}).data || {}).attributes) ? (API_BASE + (Bild || Logo).data.attributes.url) : "/pmp.png"} description={Beschreibung || Kategorie} />
            </Link>
          ))}
      </Slider>
    );
  }
}

class CityCard extends Component {
  render() {
    const url = `url(${this.props.url})`;
    return (
      <div className="city-card-wrapper">
        <div className="city-card">
          <div
            className="city-card-img"
            style={{
              backgroundImage: url
            }}
          />
          <div className="city-details">
            <div id="triangle" />
            <p>{this.props.city}</p>
            <div id="city-hidden">
              <p>{this.props.description}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TopVersorgungspartner;
