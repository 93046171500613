import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ErrorBoundary from './ErrorBoundary';
import { ConfigProvider } from 'antd';
import deDE from 'antd/es/locale/de_DE';
import moment from 'moment';
import 'moment/locale/de';

import dayjs from 'dayjs';

import 'dayjs/locale/de';

dayjs.locale('de');
moment.locale('de');

ReactDOM.render(
  <ErrorBoundary>
    <ConfigProvider locale={deDE}>
      <App />
    </ConfigProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
